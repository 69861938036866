import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { nullableStringOrNumberIsValidNumber } from '~utils/utilFunctions';

type DeliveredQuantityEstimateArgs = {
  grossCapacity: number;
  loadsPerTruck: number;
  quantity?: number;
  truckCount: number;
};

type DeliveredPerTruckEstimateArgs = {
  grossCapacity: number;
  loadsPerTruck: number;
};

type TotalLoadsEstimateArgs = {
  loadsPerTruck: number;
  truckCount: number;
};

type UnitsPerHourEstimateArgs = DeliveredQuantityEstimateArgs & { jobTime: number };

export default function useDeliveredQuantitiesEstimate() {
  const form = useFormContext();

  const watchedGrossCapacity = form.watch('grossCapacity') as string | number | null;
  const watchedLoadsPerTruck = form.watch('loadsPerTruck') as string | number | null;
  const watchedTruckCount = form.watch('truckCount') as string | number | null;
  const watchedJobTime = form.watch('jobTime') as string | number | null;
  const watchedQuantity = form.watch('quantity') as string | number | null;

  const deliveredQuantityEstimateArgs: DeliveredQuantityEstimateArgs | null =
    useMemo(() => {
      const isValidGrossCapacity =
        nullableStringOrNumberIsValidNumber(watchedGrossCapacity) &&
        Number(watchedGrossCapacity) > 0;

      const isValidLoadsPerTruck =
        nullableStringOrNumberIsValidNumber(watchedLoadsPerTruck) &&
        Number(watchedLoadsPerTruck) > 0;

      const isValidTruckCount =
        nullableStringOrNumberIsValidNumber(watchedTruckCount) &&
        Number(watchedTruckCount) > 0;

      const canEstimate =
        isValidGrossCapacity && isValidLoadsPerTruck && isValidTruckCount;

      if (!canEstimate) {
        const isValidQuantity =
          nullableStringOrNumberIsValidNumber(watchedQuantity) &&
          Number(watchedQuantity) > 0;

        if (isValidQuantity) {
          return {
            grossCapacity: 0,
            loadsPerTruck: 0,
            truckCount: 0,
            quantity: Number(watchedQuantity),
          };
        }

        return null;
      }

      return {
        grossCapacity: Number(watchedGrossCapacity),
        loadsPerTruck: Number(watchedLoadsPerTruck),
        truckCount: Number(watchedTruckCount),
      };
    }, [watchedGrossCapacity, watchedLoadsPerTruck, watchedTruckCount, watchedQuantity]);

  const deliveredPerTruckEstimateArgs: DeliveredPerTruckEstimateArgs | null =
    useMemo(() => {
      const isValidGrossCapacity =
        nullableStringOrNumberIsValidNumber(watchedGrossCapacity) &&
        Number(watchedGrossCapacity) > 0;

      const isValidLoadsPerTruck =
        nullableStringOrNumberIsValidNumber(watchedLoadsPerTruck) &&
        Number(watchedLoadsPerTruck) > 0;

      const canEstimate = isValidGrossCapacity && isValidLoadsPerTruck;

      if (!canEstimate) {
        return null;
      }

      return {
        grossCapacity: Number(watchedGrossCapacity),
        loadsPerTruck: Number(watchedLoadsPerTruck),
      };
    }, [watchedGrossCapacity, watchedLoadsPerTruck]);

  const totalLoadsEstimateArgs: TotalLoadsEstimateArgs | null = useMemo(() => {
    const isValidTruckCount =
      nullableStringOrNumberIsValidNumber(watchedTruckCount) &&
      Number(watchedTruckCount) > 0;

    const isValidLodasPerTruck =
      nullableStringOrNumberIsValidNumber(watchedLoadsPerTruck) &&
      Number(watchedLoadsPerTruck) > 0;

    const canEstimate = isValidTruckCount && isValidLodasPerTruck;

    if (!canEstimate) {
      return null;
    }

    return {
      truckCount: Number(watchedTruckCount),
      loadsPerTruck: Number(watchedLoadsPerTruck),
    };
  }, [watchedTruckCount, watchedLoadsPerTruck]);

  const unitsPerHourEstimateArgs: UnitsPerHourEstimateArgs | null = useMemo(() => {
    const isValidJobTime =
      nullableStringOrNumberIsValidNumber(watchedJobTime) && Number(watchedJobTime) > 0;

    const canEstimate = deliveredQuantityEstimateArgs && isValidJobTime;

    if (!canEstimate) {
      return null;
    }

    return { ...deliveredQuantityEstimateArgs, jobTime: Number(watchedJobTime) };
  }, [JSON.stringify(deliveredQuantityEstimateArgs), watchedJobTime]);

  const estimateDeliveredQuantity = useCallback(() => {
    if (!deliveredQuantityEstimateArgs) {
      return;
    }

    const { truckCount, loadsPerTruck, grossCapacity, quantity } =
      deliveredQuantityEstimateArgs;

    return quantity ?? truckCount * loadsPerTruck * grossCapacity;
  }, [JSON.stringify(deliveredQuantityEstimateArgs)]);

  const estimateDeliveredPerTruck = useCallback(() => {
    if (!deliveredPerTruckEstimateArgs) {
      return;
    }

    const { loadsPerTruck, grossCapacity } = deliveredPerTruckEstimateArgs;

    return loadsPerTruck * grossCapacity;
  }, [JSON.stringify(deliveredPerTruckEstimateArgs)]);

  const estimateTotalLoads = useCallback(() => {
    if (!totalLoadsEstimateArgs) {
      return;
    }

    const { truckCount, loadsPerTruck } = totalLoadsEstimateArgs;

    return truckCount * loadsPerTruck;
  }, [JSON.stringify(totalLoadsEstimateArgs)]);

  const estimateUnitsPerHour = useCallback(() => {
    const deliveredQuantity = estimateDeliveredQuantity();

    if (!unitsPerHourEstimateArgs || _.isNil(deliveredQuantity)) {
      return;
    }

    const { jobTime } = unitsPerHourEstimateArgs;

    return Number((deliveredQuantity / jobTime).toFixed(2));
  }, [estimateDeliveredQuantity, JSON.stringify(unitsPerHourEstimateArgs)]);

  return {
    estimateDeliveredPerTruck,
    estimateDeliveredQuantity,
    estimateTotalLoads,
    estimateUnitsPerHour,
  };
}
