import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { TextFormField } from '~components/FormFields/TextFormField';
import TreadLiveMapV2 from '~components/Maps/v2/TreadLiveMapV2';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { FeatureFlags } from '~constants/featureFlags';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import { Job, useJob } from '~hooks/useJob';
import { Order, useOrdersDispatch } from '~hooks/useOrders';
import TreadLiveMap from '~pages/LiveMap/TreadLiveMap';
import { Label } from '~pages/Sales/Orders/NewOrderForm/shared-components';
import {
  NewOrderFormSchemaInterface,
  setDefaultOrderValues,
} from '~pages/Sales/Orders/newOrderFormSchema';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';

import { doneOrderStates } from '../constants/orderStates';
import { OrderDetails } from '../OrderDetails';
import JobsTable from './JobsTable';

interface OrderDispatchCardExpandedBlockProps {
  checkedJobs?: string[];
  onCheckedJobStateChange?: (jobId: string) => void;
  onCreateJobFromOrder: () => void;
  order: Order;
}

enum OrderDispatchCardExpandedBlockTab {
  JOBS,
  ORDER_DETAILS,
  LIVE_MAP,
}

export default function OrderDispatchCardExpandedBlock({
  checkedJobs,
  onCheckedJobStateChange,
  onCreateJobFromOrder,
  order,
}: OrderDispatchCardExpandedBlockProps) {
  const { userStore } = useStores();
  const [selectedTab, setSelectedTab] = useState(OrderDispatchCardExpandedBlockTab.JOBS);
  const [isEditingJobs, setIsEditingJobs] = useState(false);

  const showLiveMapV2 = useFeatureFlag({
    featureFlagKey: FeatureFlags.googleMapsLiveMapV2,
  });

  const { canCreateJob, canEditJob } = userStore.getPermissions();
  const canAddNewJobs =
    order.editable && !doneOrderStates.includes(order.state) && canCreateJob;
  const canEditJobs = order.editable && canEditJob;

  const handleSelectedTabChange = useCallback(
    (__: React.SyntheticEvent, value: OrderDispatchCardExpandedBlockTab) => {
      setSelectedTab(value);
    },
    [],
  );

  const { updateOrder } = useOrdersDispatch();
  const { updateJob } = useJob();

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      order: setDefaultOrderValues(order),
      jobs: {},
    },
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, dirtyFields },
  } = form;

  // Reset order values when in edit mode in case rtu changes updates the order
  useEffect(() => {
    setValue('order', setDefaultOrderValues(order));
  }, [isEditingJobs, order]);

  const onEditJobsClick = useCallback(() => {
    setIsEditingJobs(true);
  }, []);

  const onCancelJobsClick = useCallback(() => {
    reset();
    setIsEditingJobs(false);
  }, []);

  const onSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      if (Object.keys(dirtyFields).length === 0) {
        setIsEditingJobs(false);
        return;
      }

      const updatedOrderDetails = {
        ...data.order,
        customerRate: order.customerRate,
        customerRateType: order.customerRateType,
        customerRateValue: order.customerRateValue,
        vendorRate: order.vendorRate,
        vendorRateType: order.vendorRateType,
        vendorRateValue: order.vendorRateValue,
        driverRate: order.driverRate,
        driverRateType: order.driverRateType,
        driverRateValue: order.driverRateValue,
      };

      // avoid unnecessary update if truck count is the same
      if (data.order.truckCount !== order.truckCount) {
        await updateOrder(updatedOrderDetails as unknown as NewOrderFormSchemaInterface);
      }

      // update jobs
      await Promise.all(
        Object.entries(data.jobs)
          .filter(([id]) => dirtyFields.jobs && id in dirtyFields.jobs)
          .map(([id, job]) => {
            // If the job has a requested loads count and an equipment type gross capacity, use the gross capacity times the requested loads count
            // to try to align the quantity based on the requested loads count.
            const jobData = job as Job;
            const quantity =
              jobData.requestedLoadsCount && jobData.equipmentTypeGrossCapacity
                ? Number(jobData.equipmentTypeGrossCapacity) * jobData.requestedLoadsCount
                : jobData?.quantity ?? null;
            jobData.quantity = quantity.toString();

            updateJob(id, jobData);
          }),
      );
      reset();
      setIsEditingJobs(false);
    })();
  }, [handleSubmit, reset, dirtyFields]);

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          px: 1,
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleSelectedTabChange}
          sx={{
            '&.MuiTabs-root': { minHeight: 'auto', px: 1, mt: 2 },
            '& .MuiTabs-flexContainer': { gap: 1 },
            '& .MuiTabs-indicator': {
              backgroundColor: theme.brandV2.colors.treadBlack,
              borderRadius: 0,
              height: '2px',
            },
            '& .MuiTab-root': {
              fontSize: '12px',
              lineHeight: '32px',
              minHeight: 'auto',
              minWidth: 'auto',
              px: 1,
              py: 0,
              '&.Mui-selected': {
                color: theme.brandV2.colors.treadBlack,
              },
            },
          }}
        >
          <Tab label={t('form_fields.jobs')} />
          <Tab label={t('order.form.order_details')} />
          <Tab label={t('live_map.live_map')} />
        </Tabs>

        <Box>
          {!isEditingJobs && canEditJobs && (
            <Box
              sx={{
                display: 'inline-block',
                height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
              }}
            >
              <SmallButton
                color="brandV2Orange"
                variant="text"
                onClick={(event) => {
                  event.stopPropagation();
                  onEditJobsClick();
                }}
                sx={{
                  '&.MuiButtonBase-root': {
                    border: 'solid 1px transparent ',
                    '&:hover': { borderColor: theme.brandV2.colors.treadOrange },
                  },
                }}
              >
                {t('dispatch.order.edit_trucks')}
              </SmallButton>
            </Box>
          )}
          {!isEditingJobs && canAddNewJobs && (
            <SmallButton
              color="brandV2Orange"
              variant="text"
              startIcon={<AddCircleOutline />}
              onClick={(event) => {
                event.stopPropagation();
                onCreateJobFromOrder();
              }}
              sx={{
                '&.MuiButtonBase-root': {
                  border: 'solid 1px transparent',
                  px: 1,
                  '.MuiButton-startIcon': { mr: 0.5 },
                  '.MuiSvgIcon-root': { fontSize: '16px' },
                  '&:hover': { borderColor: theme.brandV2.colors.treadOrange },
                },
              }}
            >
              {t('form_fields.job')}
            </SmallButton>
          )}

          {/* Buttons when in edit jobs mode */}
          {isEditingJobs && (
            <Box display="flex" gap={1} alignItems="center" height="48px">
              <Box display="flex" alignItems="center" gap={1}>
                <Label isBold sx={{ wordWrap: 'nowrap' }}>
                  {t('dispatch.order.delivery_estimates.truck_qty')}
                </Label>
                <TextFormField
                  control={control}
                  errors={errors}
                  isRequired
                  name="order.truckCount"
                  type="number"
                  margin="none"
                  sx={{ width: '60px' }}
                  inputProps={{
                    sx: {
                      fontSize: '12px',
                    },
                  }}
                />
              </Box>

              <Divider
                orientation={'vertical'}
                variant={'fullWidth'}
                flexItem
                sx={{ mx: 3 }}
              ></Divider>

              <Box display="flex" gap={1}>
                <SmallButton
                  color="secondary"
                  onClick={onCancelJobsClick}
                  variant="outlined"
                  sx={{
                    '&.MuiButtonBase-root': {
                      height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
                    },
                  }}
                >
                  {t('actions.cancel')}
                </SmallButton>
                <SmallButton
                  color="brandV2Yellow"
                  onClick={onSubmit}
                  sx={{
                    '&.MuiButtonBase-root': {
                      height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
                    },
                  }}
                >
                  {t('actions.save')}
                </SmallButton>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Box>
        {selectedTab === OrderDispatchCardExpandedBlockTab.JOBS && (
          <FormProvider {...form}>
            <JobsTable
              order={order}
              checkedJobs={checkedJobs}
              onCheckedStateChange={onCheckedJobStateChange}
              isEditingJobs={isEditingJobs}
            />
          </FormProvider>
        )}

        {selectedTab === OrderDispatchCardExpandedBlockTab.ORDER_DETAILS && (
          <Box p={1}>
            <OrderDetails order={order} />
          </Box>
        )}

        {selectedTab === OrderDispatchCardExpandedBlockTab.LIVE_MAP && (
          <Box
            m={1}
            borderRadius={1}
            overflow={'hidden'}
            border={1}
            sx={{
              background: '#fff',
              height: 400,
              borderColor: theme.brandV2.colors.treadGray7,
            }}
          >
            {showLiveMapV2 ? (
              <TreadLiveMapV2 order={order} />
            ) : (
              <TreadLiveMap order={order} />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
