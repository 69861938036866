import './marker.css';

import Popover from '@mui/material/Popover';
import { Marker, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useMemo, useState } from 'react';

import { GOOGLE_MAPS_LOADER_OPTIONS, MarkerType } from './constants';
import { TreadMarkerProps } from './types';

type Props = TreadMarkerProps;

export function TreadMarker(marker: Props) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [popoverVersion, setPopoverVersion] = useState<'hover' | 'click'>(
    marker.type === MarkerType.truck ? 'hover' : 'click',
  );

  const { isLoaded } = useJsApiLoader(GOOGLE_MAPS_LOADER_OPTIONS);

  const markerTypeToIcon: Record<
    MarkerType,
    google.maps.Icon | google.maps.Symbol
  > | null = useMemo(() => {
    if (!isLoaded) return null;
    return {
      [MarkerType.truck]: {
        path: 'M14 0 28 30 14 24 0 30 14 0',
        fillColor: '#0044FF',
        fillOpacity: 1,
        scale: 0.6,
        anchor: new window.google.maps.Point(16, 17),
        labelOrigin: new window.google.maps.Point(16, 56),
      },
      [MarkerType.site]: {
        path: 'M29,4.8 C19.6,4.8 14.4,10.8 14.4,19.2 C14.4,28.8 29,48 29,48 C29,48 43.6,28.8 43.6,19.2 C43.6,10.8 38.4,4.8 29,4.8 Z M29,24 A4.8,4.8 0 1,1 29,14.4 A4.8,4.8 0 1,1 29,24 Z',
        fillColor: '#F05039',
        fillOpacity: 1,
        strokeWeight: 2,
        scale: 0.9,
        strokeColor: '#ffffff',
        anchor: new window.google.maps.Point(30, 42),
      },
      [MarkerType.moving_site]: {
        path: 'M10,2 A8,8 0 1,0 10,18 A8,8 0 1,0 10,2',
        fillColor: 'black',
        fillOpacity: 1,
        scale: 0.9,
        strokeWeight: 2,
        strokeColor: '#ffffff',
        anchor: new window.google.maps.Point(9, 9),
      },
      [MarkerType.ping]: {
        path: 'M10,2 A8,8 0 1,0 10,18 A8,8 0 1,0 10,2',
        fillColor: '#6e6f70',
        fillOpacity: 1,
        scale: 0.6,
        strokeWeight: 0,
        anchor: new window.google.maps.Point(9, 9),
      },
    };
  }, [isLoaded]);

  const approximateLabelOffset = (rotation: number, label: string) => {
    const isMultiLine = label.includes('\n');
    if (rotation > 315 || rotation <= 45) {
      // N
      return new window.google.maps.Point(16, isMultiLine ? 72 : 56);
    }
    if (rotation > 45 && rotation <= 135) {
      // E
      return new window.google.maps.Point(isMultiLine ? 72 : 56, 16);
    }
    if (rotation > 135 && rotation <= 225) {
      // S
      return new window.google.maps.Point(16, isMultiLine ? -44 : -24);
    }
    // W
    return new window.google.maps.Point(isMultiLine ? -44 : -24, 16);
  };

  const isInteractive =
    marker.type === MarkerType.site || marker.type === MarkerType.truck;
  const isDraggable = marker.type === MarkerType.site && marker.draggable;

  const { popoverExists, popoverContent } = useMemo(() => {
    if (
      marker.type === MarkerType.site &&
      !marker.draggable &&
      marker.clickPopoverContent
    ) {
      return { popoverExists: true, popoverContent: marker.clickPopoverContent };
    }
    if (marker.type === MarkerType.truck) {
      return {
        popoverExists: true,
        popoverContent:
          popoverVersion === 'hover'
            ? marker.hoverPopoverContent
            : marker.clickPopoverContent,
      };
    }
    return { popoverExists: false, popoverContent: null };
  }, [marker, popoverVersion]);

  const togglePopoverFromMarker = useCallback(
    (event: google.maps.MapMouseEvent) => {
      if (anchorEl) {
        setAnchorEl(null);
      } else {
        setAnchorEl(event.domEvent.currentTarget as Element);
      }
    },
    [anchorEl, marker.type, popoverVersion, setPopoverVersion, setAnchorEl],
  );

  return (
    <>
      <Marker
        position={{
          lng: marker.lng,
          lat: marker.lat,
        }}
        animation={google.maps.Animation.DROP}
        label={
          marker.type === MarkerType.truck
            ? {
                text: marker.label,
                color: 'white',
                className: 'marker-label',
              }
            : undefined
        }
        icon={
          markerTypeToIcon?.[marker.type]
            ? {
                ...markerTypeToIcon[marker.type],
                ...(marker.isActive
                  ? {
                      fillColor: marker.type === MarkerType.truck ? '#163786' : '#CB0000',
                    }
                  : {}),
                ...(marker.type === MarkerType.truck
                  ? {
                      rotation: marker.bearing,
                      labelOrigin: approximateLabelOffset(marker.bearing, marker.label),
                    }
                  : {}),
              }
            : undefined
        }
        draggable={isDraggable}
        onMouseDown={isInteractive ? marker.onMouseDown : undefined}
        onMouseUp={isInteractive ? marker.onMouseUp : undefined}
        onMouseOver={
          isInteractive
            ? marker.type === MarkerType.truck
              ? (event) => {
                  togglePopoverFromMarker(event);
                  marker.onMouseOver();
                }
              : marker.onMouseOver
            : undefined
        }
        onMouseOut={isInteractive ? marker.onMouseOut : undefined}
        onDragEnd={
          isDraggable
            ? (event) => {
                marker.onDragEnd?.({
                  lat: event.latLng?.lat() ?? 0,
                  lng: event.latLng?.lng() ?? 0,
                });
              }
            : undefined
        }
        onClick={popoverExists ? togglePopoverFromMarker : undefined}
      />
      {popoverExists && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
            if (marker.type === MarkerType.truck) {
              setPopoverVersion('hover');
            }
          }}
          onClick={
            marker.type === MarkerType.truck && popoverVersion === 'hover'
              ? () => {
                  setPopoverVersion('click');
                }
              : undefined
          }
          onMouseOut={
            marker.type === MarkerType.truck && popoverVersion === 'hover'
              ? () => {
                  setAnchorEl(null);
                }
              : undefined
          }
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {popoverContent}
        </Popover>
      )}
    </>
  );
}
