import Refresh from '@mui/icons-material/Refresh';
import { buttonBaseClasses, buttonClasses, svgIconClasses } from '@mui/material';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';

import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

interface RefreshOrdersListButtonProps {
  onClick: () => void;
}

const RefreshOrdersListButton = observer(({ onClick }: RefreshOrdersListButtonProps) => {
  const { ordersDispatchStore } = useStores();

  if (!ordersDispatchStore.hasPendingUpdates || ordersDispatchStore.deepLinkedOrder) {
    return null;
  }

  return (
    <SmallButton
      color="inherit"
      onClick={onClick}
      startIcon={<Refresh />}
      variant="outlined"
      sx={{
        [`&.${buttonBaseClasses.root}`]: {
          border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
          height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
          mr: -1,
          pl: 0.5,
          pr: 1,
          py: 0,
          [`.${buttonClasses.startIcon}`]: { ml: 0, mr: 0.5 },
          [`.${svgIconClasses.root}`]: { fontSize: '24px' },
        },
      }}
    >
      {t('order.new_orders_available')}
    </SmallButton>
  );
});

export default RefreshOrdersListButton;
