import type { Subscription } from '@rails/actioncable';
import {
  getV1OrdersOrderIdPhasesTypeahead,
  Order_Read,
  Order_Read_Typeahead,
  OrderState,
  patchV1OrdersId,
  postV1OrdersEstimate,
} from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { DeparsedOrderEstimate, Order, OrderEstimate } from '~hooks/useOrders/models';
import { NewOrderFormSchemaInterface } from '~pages/Sales/Orders/newOrderFormSchema';
import connection from '~services/connectionModule';
import { PaginationLink, PaginationQuery } from '~services/pagination';
import { realTimeChannels } from '~services/realTimeChannels';
import { rootStore, useStores } from '~store';

import { Phase } from '../useProjects/models';

export type OrderEventType =
  | 'request'
  | 'start'
  | 'accept'
  | 'reject'
  | 'cancel'
  | 'complete';

interface UpdateOrderParams {
  order: NewOrderFormSchemaInterface;
  callBack?: (order: Order) => void;
}
interface CreateOrderParams {
  order: NewOrderFormSchemaInterface;
  callBack?: (order: Order) => void;
}
interface DeleteOrderParams {
  id: string;
  callBack?: () => void;
}

interface GetOrdersByCompanyIdProps {
  companyId: string;
  link?: PaginationLink;
  searchQuery?: string;
  filterParams?: Record<string, typeof rootStore.ordersStore.orderFilter>;
}

interface GetOrdersByCompanyIdTypeaheadProps {
  callback?: (project: Order[]) => void;
  companyId: string;
  states?: OrderState[];
  limit?: number;
  query?: string;
  cursor?: string;
}

interface GetOrdersByCompanyIdTypeaheadQueryProps {
  'page[limit]': number;
  'search[query]': string;
  'filter[states]': OrderState[];
  'page[after]': string;
}

interface GetOrderPhasesTypeaheadProps {
  orderId: string;
  limit?: number;
  query?: string;
  cursor?: string;
}

interface GetOrderPhasesTypeaheadQueryProps {
  'page[limit]': number;
  'search[query]': string;
  'page[after]': string;
}
export const useOrders = () => {
  const [isLoadingOrders, setIsLoadingOrders] = useState<boolean>(false);
  const [isLoadingOrderPhases, setIsLoadingOrderPhases] = useState(false);

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { ordersStore, userStore } = useStores();
  const [orderSubscription, setOrderSubscription] = useState<Subscription>();
  const [isSendingSms, setIsSendingSms] = useState(false);
  const [isEstimatingOrder, setIsEstimatingOrder] = useState(false);

  const { addSearchHeaderParam } = useDataGridSearch();
  const companyId = userStore?.currentCompanies?.[0]?.id || userStore?.userCompany?.id;

  const subscribeToOrderUpdates = (orderUpdateCallback?: (order: Order) => void) => {
    const consumer = connection.realTimeConnection;
    const subscription = consumer?.subscriptions?.create?.(
      {
        channel: realTimeChannels.CompanyOrderUpdateChannel,
        // This should be the user's company_id _or_ the company_id scoped in a given
        // Page?
        company_id: companyId,
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        connected: () => () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        disconnected: () => () => {},
        received: (resp: { data: Order_Read }) => {
          //This message is broadcast to all client including the one who sent the request, will trigger 2*render
          const order = Order.parse(resp?.data);

          // If not last page
          if (userStore.isCurrentCompanyActive) {
            if (!ordersStore.ordersPagination.after) {
              ordersStore.addOrder(order);
            } else {
              ordersStore.updateOrder(order);
            }
          } else {
            if (!ordersStore.childCompanyOrdersPagination.after) {
              ordersStore.addChildCompanyOrder(order);
            } else {
              ordersStore.updateChildCompanyOrder(order);
            }
          }

          orderUpdateCallback?.(order);
        },
      },
    );
    setOrderSubscription(subscription);
  };
  const getOrderById = async (id: string) => {
    setIsLoadingOrders(true);
    try {
      const resp = await connection.get<Order_Read>(
        `${API_VERSION}/orders/${id}`,
        {},
        $t('error_messages.orders.failed_to_fetch_order') as string,
      );
      const order = Order.parse(resp);

      return order;
    } finally {
      setIsLoadingOrders(false);
    }
  };

  const getOrdersByCompanyId = async ({
    companyId,
    filterParams,
    link,
    searchQuery,
  }: GetOrdersByCompanyIdProps) => {
    setIsLoadingOrders(true);
    let params: PaginationQuery = {
      'page[limit]': ordersStore.childCompanyOrdersPagination.limit,
    };

    if (link && ordersStore.childCompanyOrdersPagination[link]) {
      params[`page[${link}]`] = ordersStore.childCompanyOrdersPagination[link];
    }

    params = addSearchHeaderParam({
      searchValue: searchQuery,
      filterParams,
      params,
    });

    try {
      const { data, pagination } = await connection.getPaginated<Order_Read>(
        `${API_VERSION}/companies/${companyId}/orders`,
        { params },
        $t('error_messages.orders.failed_to_fetch') as string,
      );
      const formatted = data.map(Order.parse);
      ordersStore.setChildCompanyOrders(formatted);
      ordersStore.setChildCompanyOrdersPagination(pagination);
      ordersStore.updateChildCompanyOrdersPageNumber(link);
    } finally {
      setIsLoadingOrders(false);
    }
  };

  const getOrdersByCompanyIdTypeahead = async (
    options: GetOrdersByCompanyIdTypeaheadProps,
  ) => {
    const params: Partial<GetOrdersByCompanyIdTypeaheadQueryProps> = {
      'page[limit]': 30,
    };

    if (options?.query) {
      params['search[query]'] = options.query;
    }

    if (options?.states) {
      params['filter[states]'] = options.states;
    }

    if (options?.cursor) {
      params['page[after]'] = options.cursor;
    }

    try {
      setIsLoadingOrders(true);
      const { data, pagination } = await connection.getPaginated<Order_Read_Typeahead>(
        `${API_VERSION}/companies/${options.companyId}/orders/typeahead`,
        { params },
        $t('error_messages.orders.failed_to_fetch') as string,
      );
      const projects = (data as Order_Read[]).map((project) => Order.parse(project));
      options.callback?.(projects);
      return { data: projects, pagination };
    } catch (e: unknown) {
      throw new Error(`Failed to fetch orders: ${e}`);
    } finally {
      setIsLoadingOrders(false);
    }
  };

  const createOrder = async ({ order, callBack }: CreateOrderParams) => {
    setIsUpdating(true);
    try {
      await connection
        .post<Order_Read>(
          `${API_VERSION}/orders`,
          Order.deparse(order),
          {},
          $t('error_messages.orders.failed_to_create') as string,
        )
        .then((resp) => {
          const orderSaved = Order.parse(resp);

          // If not last page
          if (userStore.isCurrentCompanyActive) {
            if (!ordersStore.ordersPagination.after) {
              ordersStore.addOrder(orderSaved);
            }
          } else {
            if (!ordersStore.childCompanyOrdersPagination.after) {
              ordersStore.addChildCompanyOrder(orderSaved);
            }
          }

          callBack?.(orderSaved);
        });
    } finally {
      setIsUpdating(false);
    }
  };
  const updateOrder = async ({ order, callBack }: UpdateOrderParams) => {
    setIsUpdating(true);
    try {
      return await connection
        .patch<Order_Read>(
          `${API_VERSION}/orders/${order.id}`,
          Order.deparseUpdate(order),
          {},
          $t('error_messages.orders.failed_to_update') as string,
        )
        .then((resp) => {
          const orderSaved = Order.parse(resp);
          if (userStore.isCurrentCompanyActive) {
            ordersStore.updateOrder(orderSaved);
          } else {
            ordersStore.updateChildCompanyOrder(orderSaved);
          }
          callBack?.(orderSaved);
        });
    } finally {
      setIsUpdating(false);
    }
  };
  const getCompanyOrders = (
    link?: PaginationLink,
    searchQuery?: string,
    filterParams?: Record<string, any>,
  ) => {
    setIsLoadingOrders(true);
    let params: PaginationQuery = {
      'page[limit]': ordersStore.ordersPagination.limit,
    };

    if (link && ordersStore.ordersPagination[link]) {
      params[`page[${link}]`] = ordersStore.ordersPagination[link];
    }

    params = addSearchHeaderParam({
      searchValue: searchQuery,
      filterParams,
      params,
    });
    return connection
      .getPaginated<Order_Read>(
        `${API_VERSION}/orders`,
        { params },
        $t('error_messages.orders.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        const formatted = data.map(Order.parse);
        ordersStore.setOrders(formatted);
        ordersStore.setOrdersPagination(pagination);
        ordersStore.updateOrdersPageNumber(link);
      })
      .finally(() => {
        setIsLoadingOrders(false);
      });
  };
  const doEvent = (id: string, event: OrderEventType, data?: any) => {
    setIsUpdating(true);

    return connection
      .put<Order_Read>(
        `${API_VERSION}/orders/${id}/${event}`,
        data || undefined,
        {},
        $t('error_messages.orders.failed_to_do_order_event', { event }) as string,
      )
      .then((resp) => {
        const formatted = Order.parse(resp);
        ordersStore.updateOrder(formatted);
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const deleteOrder = async ({ id, callBack }: DeleteOrderParams) => {
    setIsUpdating(true);
    try {
      await connection
        .delete(
          `${API_VERSION}/orders/${id}`,
          {},
          $t('error_messages.orders.failed_to_delete') as string,
        )
        .then(() => {
          ordersStore.deleteOrder(id);
          callBack?.();
        });
    } finally {
      setIsUpdating(false);
    }
  };

  const cloneOrder = async (id: string, includeAssignee: boolean = false) => {
    setIsUpdating(true);
    const requestBody = {
      include_assignee: includeAssignee,
    };
    try {
      const resp = await connection.post<Order_Read>(
        `${API_VERSION}/orders/${id}/copy`,
        requestBody,
        {},
        $t('error_messages.orders.failed_to_duplicate') as string,
      );
      const formatted = Order.parse(resp);
      if (!ordersStore.ordersPagination.after?.length) {
        ordersStore.addOrder(formatted);
      }

      ordersStore.addClonedOrder(formatted);
      return formatted;
    } finally {
      setIsUpdating(false);
    }
  };

  const sendSmsToAllDrivers = async (orderId: string, message: string) => {
    // Bottom is commented out to demonstrate the state that a job under the order must be in to receive the SMS
    // SEND_MESSAGE_STATES = [
    //   JobState.definitions[:accepted],
    //   JobState.definitions[:to_pickup],
    //   JobState.definitions[:arrived_pickup],
    //   JobState.definitions[:loaded],
    //   JobState.definitions[:to_dropoff],
    //   JobState.definitions[:arrived_dropoff],
    //   JobState.definitions[:unloaded],
    //   JobState.definitions[:load_completed],
    //   JobState.definitions[:in_review],

    // ]
    try {
      setIsSendingSms(true);
      await connection.put(
        `${API_VERSION}/orders/${orderId}/driver_sms`,
        { message },
        {},
        $t('error_messages.orders.failed_to_send_text') as string,
      );
    } finally {
      setIsSendingSms(false);
    }
  };

  const getOrderPhasesTypeahead = async (options: GetOrderPhasesTypeaheadProps) => {
    const params: Partial<GetOrderPhasesTypeaheadQueryProps> = {
      'page[limit]': options.limit ?? 30,
    };

    if (options?.query) {
      params['search[query]'] = options.query;
    }

    if (options?.cursor) {
      params['page[after]'] = options.cursor;
    }
    setIsLoadingOrderPhases(true);

    try {
      const response = await getV1OrdersOrderIdPhasesTypeahead({
        path: { 'order-id': options.orderId },
      });

      const phases = response.data.data.map((phase) => {
        return Phase.parse(phase);
      });
      ordersStore.setPhaseTypeheadsByOrderID(options.orderId, phases);
    } catch (err) {
      connection.handleRequestError(
        err,
        $t('error_messages.driver_days.failed_to_fetch_phases') as string,
      );
    } finally {
      setIsLoadingOrderPhases(false);
    }
  };

  const getOrderEstimate = async (args: DeparsedOrderEstimate) => {
    try {
      setIsEstimatingOrder(true);

      const response = await postV1OrdersEstimate({ body: OrderEstimate.deparse(args) });

      return OrderEstimate.parse(response.data.data);
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.orders.failed_to_estimate_order') as string,
        [422],
      );
    } finally {
      setIsEstimatingOrder(false);
    }
  };

  const addNewPhaseToOrder = async (orderId: string, name: string, code: string) => {
    try {
      setIsUpdating(true);
      const response = await patchV1OrdersId({
        path: { id: orderId },
        body: { phases: [{ name, code }] },
      });
      const updatedOrder = Order.parse(response.data.data);
      ordersStore.updateOrder(updatedOrder);
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.orders.failed_to_add_phase') as string,
        [422],
      );
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    addNewPhaseToOrder,
    cloneOrder,
    createOrder,
    deleteOrder,
    doEvent,
    getCompanyOrders,
    getOrderById,
    getOrderEstimate,
    getOrderPhasesTypeahead,
    getOrdersByCompanyId,
    getOrdersByCompanyIdTypeahead,
    isEstimatingOrder,
    isLoadingOrderPhases,
    isLoadingOrders,
    isSendingSms,
    isUpdating,
    orderSubscription,
    sendSmsToAllDrivers,
    subscribeToOrderUpdates,
    updateOrder,
  } as const;
};
