import { outlinedInputClasses } from '@mui/material';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { t } from 'i18next';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import SliderFormField from '~components/FormFields/SliderFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';
import { OrderUnitOfMeasure } from '~constants/enums';
import { NameIdSchemaRequired } from '~constants/regexConst';
import { EquipmentTypeItem, useEquipment } from '~hooks/useEquipment';
import { Order } from '~hooks/useOrders';
import { Project } from '~hooks/useProjects';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { nullableStringOrNumberIsValidNumber } from '~utils/utilFunctions';

import { getMaxTruckCount } from '../newOrderFormSchema';
import Cycle from './Cycle';

interface TrucksProps {
  order?: Order;
  parent?: Project | Order;
}

export default function Trucks({ order, parent }: TrucksProps) {
  const form = useFormContext();
  const { userStore, toasterStore } = useStores();

  const {
    createEquipmentTypeByCompanyId,
    getEquipmentTypeById,
    getEquipmentTypesByCompanyIdTypeahead,
    isLoading: isEquipmentTypeLoading,
  } = useEquipment();

  const [notFoundEquipmentTypeName, setNotFoundEquipmentTypeName] = useState('');

  const watchedCompany = form.watch('company');
  const watchedQuantity = form.watch('quantity') as string | number | null;
  const watchedGrossCapacity = form.watch('grossCapacity');
  const watchedTruckCount = form.watch('truckCount') as string | number | null;
  const watchedUoM = form.watch('unitOfMeasure') as NameIdSchemaRequired | null;

  const companyId = watchedCompany?.id || userStore.userCompany?.id || '';
  const isLoadUoM = watchedUoM?.id === OrderUnitOfMeasure.LOAD;

  const maxTruckCount = useMemo(() => {
    return getMaxTruckCount(watchedQuantity, watchedGrossCapacity);
  }, [watchedQuantity, watchedGrossCapacity]);

  const createEquipmentType = useCallback(async () => {
    if (!companyId) {
      toasterStore.push(
        alert(t('form_validation_errors.no_user_company'), AlertTypes.error),
      );

      return;
    }

    const createdEquipmentType = await createEquipmentTypeByCompanyId({
      companyId,
      equipmentType: { name: notFoundEquipmentTypeName } as unknown as EquipmentTypeItem,
    });

    form.setValue('equipmentType', createdEquipmentType, {
      shouldValidate: true,
      shouldDirty: true,
    });

    toasterStore.push(
      alert(
        t('administration.equipment.equipment_created', {
          name: createdEquipmentType.name,
        }),
        AlertTypes.success,
      ),
    );
  }, [companyId, notFoundEquipmentTypeName]);

  const fetchEquipmentType = useCallback(
    async (equipmentTypeId: string, grossCapacityOverride?: number) => {
      const equipmentType = await getEquipmentTypeById(equipmentTypeId);

      form.setValue('equipmentType', equipmentType, {
        shouldValidate: true,
        shouldDirty: true,
      });

      const grossCapacity = _.isNil(grossCapacityOverride)
        ? equipmentType.grossCapacity
        : `${grossCapacityOverride}`;

      form.setValue('grossCapacity', grossCapacity);
    },
    [],
  );

  const fetchEquipmentTypesByCompanyId = useCallback(
    (additionalProps = {}) => {
      if (!companyId) {
        toasterStore.push(
          alert(t('form_validation_errors.no_user_company'), AlertTypes.error),
        );

        return;
      }

      return getEquipmentTypesByCompanyIdTypeahead({ companyId, ...additionalProps });
    },
    [companyId],
  );

  const handleEquipmentTypeInput = useCallback(
    (_: React.SyntheticEvent, value: string) => {
      setNotFoundEquipmentTypeName(value);
    },
    [],
  );

  const handleEquipmentTypeSelect = useCallback(
    (equipmentType: EquipmentTypeItem) => {
      if (equipmentType) {
        fetchEquipmentType(equipmentType.id);
      }
    },
    [fetchEquipmentType],
  );

  useEffect(() => {
    if (isLoadUoM) {
      form.setValue('grossCapacity', 1);

      const isValidQuantity =
        nullableStringOrNumberIsValidNumber(watchedQuantity) &&
        Number(watchedQuantity) > 0;

      const isValidTruckCount =
        nullableStringOrNumberIsValidNumber(watchedTruckCount) &&
        Number(watchedTruckCount) > 0;

      if (isValidQuantity) {
        const quantity = Number(watchedQuantity);
        const truckCount = isValidTruckCount ? Number(watchedTruckCount) : 1;

        form.setValue('totalLoads', quantity);
        form.setValue('loadsPerTruck', Math.round(quantity / truckCount));
      }
    }
  }, [isLoadUoM, watchedQuantity, watchedTruckCount]);

  useEffect(() => {
    if (!order?.id && parent?.equipmentType?.id) {
      fetchEquipmentType(parent.equipmentType.id ?? '');
    }
  }, [order?.id, parent?.equipmentType?.id, fetchEquipmentType]);

  useEffect(() => {
    if (order?.id) {
      const equipmentTypeId = order.equipmentType?.id;

      if (equipmentTypeId) {
        fetchEquipmentType(
          equipmentTypeId,
          order.equipmentTypeGrossCapacity ?? undefined,
        );
      }
    }
  }, [order, fetchEquipmentType]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <AutocompleteAsyncFormField
          asyncCallback={fetchEquipmentTypesByCompanyId}
          control={form.control}
          errors={form.formState.errors}
          getLabel={(item) => item?.name ?? ''}
          getValue={(item) => item?.id ?? ''}
          label={`${t('dispatch.order.order_details_v2.truck_type')}`}
          name="equipmentType"
          noOptionsText={
            notFoundEquipmentTypeName ? (
              <Chip
                color="primary"
                disabled={isEquipmentTypeLoading}
                label={t('common.add_new_name', {
                  name: notFoundEquipmentTypeName || '',
                })}
                onClick={createEquipmentType}
                size="small"
                variant="filled"
                sx={{ cursor: 'pointer' }}
              />
            ) : (
              t('form_fields.no_options')
            )
          }
          onInput={handleEquipmentTypeInput}
          onSelect={handleEquipmentTypeSelect}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFormField
          control={form.control}
          disabled={isLoadUoM}
          errors={form.formState.errors}
          label={`${t('dispatch.order.order_details_v2.truck_capacity')}`}
          name="grossCapacity"
          type="number"
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <FormSectionTitle
          title={t('dispatch.order.cycle.title')}
          sx={{ fontSize: '14px', mb: 1 }}
        />

        <Cycle />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={6}>
        <SliderFormField
          control={form.control}
          label={`${t('dispatch.order.order_details_v2.truck_duration')}`}
          max={16}
          min={1}
          name="jobTime"
          step={0.25}
        />
      </Grid>

      <Grid item xs={2}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          label="&nbsp;"
          name="jobTime"
          type="number"
          sx={{
            [`& .${outlinedInputClasses.root}`]: { pr: 0 },
            [`& .${outlinedInputClasses.input}`]: { fontSize: '12px', px: 1.5 },
          }}
        />
      </Grid>

      <Grid item xs={0.5} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider orientation="vertical" />
      </Grid>

      <Grid item xs={3.5}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('dispatch.order.cycle.cycle_time')}`}
          name="cycleTime"
          type="number"
          sx={{
            [`& .${outlinedInputClasses.root}`]: { pr: 0 },
            [`& .${outlinedInputClasses.input}`]: { fontSize: '12px', px: 1.5 },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={6}>
        <SliderFormField
          control={form.control}
          label={`${t('dispatch.order.delivery_estimates.truck_qty')}`}
          max={maxTruckCount}
          min={1}
          name="truckCount"
          step={1}
        />
      </Grid>

      <Grid item xs={2}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          isRequired
          name="truckCount"
          type="number"
          sx={{
            mt: '17px',
            [`& .${outlinedInputClasses.root}`]: { pr: 0 },
            [`& .${outlinedInputClasses.input}`]: { fontSize: '12px', px: 1.5 },
          }}
        />
      </Grid>

      <Grid item xs={0.5} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider orientation="vertical" />
      </Grid>

      <Grid item xs={3.5}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('dispatch.order.delivery_estimates.loads_per_truck')}`}
          name="loadsPerTruck"
          type="number"
          sx={{
            [`& .${outlinedInputClasses.root}`]: { pr: 0 },
            [`& .${outlinedInputClasses.input}`]: { fontSize: '12px', px: 1.5 },
          }}
        />
      </Grid>
    </Grid>
  );
}
