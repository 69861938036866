import CancelOutlined from '@mui/icons-material/CancelOutlined';
import ChatOutlined from '@mui/icons-material/ChatOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import PeopleOutlineOutlined from '@mui/icons-material/PeopleOutlineOutlined';
import WarningRounded from '@mui/icons-material/WarningRounded';
import { buttonBaseClasses, svgIconClasses, tooltipClasses } from '@mui/material';
import Box from '@mui/material/Box';
import { buttonClasses, ButtonProps } from '@mui/material/Button';
import MUIChip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { OrderState, WaypointType } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { cloneElement, ReactNode, useMemo, useRef } from 'react';
import React from 'react';

import Menu, { MenuHandler } from '~components/Menu/Menu';
import { extractWaypointData } from '~components/Order/ordersDispatchColumns';
import {
  OverflowAwareText,
  SmallButton,
} from '~components/Order/ordersDispatchStyledComponents';
import OrderStatusTransitioner from '~components/Order/OrderStatusTransitioner';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import { Order } from '~hooks/useOrders';
import { GeofenceStartAdornment } from '~pages/Settings/Administration/Sites/components/GeofenceStartAdornment';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { isJobAssignable } from '~utils/jobs/job-utils';

import { doneOrderStates } from '../constants/orderStates';
import { formatNumber, Summary } from './OrderSummary';

interface OrderDispatchCardFragment {
  order: Order;
}

export const makeOrderTitle = (order: Order, prependWithOrderId: boolean = false) => {
  const fragments: string[] = [];

  if (prependWithOrderId) {
    fragments.push(`${order.orderId}`);
  }

  if (order.dispatchNumber) {
    fragments.push(`${order.dispatchNumber}`);
  }

  if (order.name) {
    fragments.push(`${order.name}`);
  } else {
    const dropOff = extractWaypointData(order.waypoints?.[1]);

    if (dropOff) {
      fragments.push(dropOff.name);
    }
  }

  return fragments.join(' • ');
};

export function Title({ order }: OrderDispatchCardFragment) {
  const mainText = makeOrderTitle(order);

  if (mainText) {
    return (
      <Typography sx={{ fontSize: '16px', fontWeight: 600, mt: '-3px' }}>
        {mainText}
      </Typography>
    );
  }

  return null;
}

export function Subtitle({ order }: OrderDispatchCardFragment) {
  const fragments: Array<JSX.Element> = [];

  const filteredSendingAccounts = order.sendingAccounts.filter(
    (account) => account.name !== order.account?.name,
  );

  const shouldShowSender = filteredSendingAccounts.length;

  if (shouldShowSender) {
    fragments.push(
      <Typography
        component="span"
        sx={{ color: theme.brandV2.colors.treadBlack, fontSize: 'inherit' }}
      >
        {t('common.sender')}:{' '}
        <Typography
          component="span"
          sx={{
            fontSize: 'inherit',
            fontWeight: 600,
            color: theme.brandV2.colors.treadGreen,
          }}
        >
          {filteredSendingAccounts.map((account) => account.name).join(', ')}
        </Typography>
      </Typography>,
    );
  }

  if (order.account?.name) {
    fragments.push(
      <Typography
        component="span"
        sx={{ color: theme.brandV2.colors.treadBlack, fontSize: 'inherit' }}
      >
        {t('common.customer')}:{' '}
        <Typography
          component="span"
          sx={{
            fontSize: 'inherit',
            fontWeight: shouldShowSender ? 'normal' : 600,
            color: shouldShowSender
              ? theme.brandV2.colors.treadGray3
              : theme.brandV2.colors.treadGreen,
          }}
        >
          {order.account.name}
        </Typography>
      </Typography>,
    );
  }

  if (order.material?.name) {
    fragments.push(
      <Typography
        component="span"
        sx={{ color: theme.brandV2.colors.treadBlack, fontSize: 'inherit' }}
      >
        {t('form_fields.material')}:{' '}
        <Typography
          component="span"
          sx={{ fontSize: 'inherit', color: theme.brandV2.colors.treadGray3 }}
        >
          {order.material.name}
        </Typography>
      </Typography>,
    );
  }

  if (order.projectName) {
    fragments.push(
      <Typography
        component="span"
        sx={{ color: theme.brandV2.colors.treadBlack, fontSize: 'inherit' }}
      >
        {t('common.project')}:{' '}
        <Typography
          component="span"
          sx={{ fontSize: 'inherit', color: theme.brandV2.colors.treadGray3 }}
        >
          {order.projectName}
        </Typography>
      </Typography>,
    );
  }

  if (order.project?.externalId) {
    fragments.push(
      <Typography
        component="span"
        sx={{ color: theme.brandV2.colors.treadBlack, fontSize: 'inherit' }}
      >
        {t('dispatch.dispatch_v3.project_external_id')}:{' '}
        <Typography
          component="span"
          sx={{ fontSize: 'inherit', color: theme.brandV2.colors.treadGray3 }}
        >
          {order.project.externalId}
        </Typography>
      </Typography>,
    );
  }

  fragments.push(
    <Typography
      component="span"
      sx={{ color: theme.brandV2.colors.treadGray3, fontSize: 'inherit' }}
    >
      {order.orderId}
    </Typography>,
  );

  return (
    <OverflowAwareText sx={{ fontSize: '14px' }}>
      {fragments.map((fragment, index) => (
        <React.Fragment key={index}>
          {fragment}

          {index !== fragments.length - 1 && (
            <Typography
              component="span"
              sx={{
                color: theme.brandV2.colors.treadGray3,
                fontSize: 'inherit',
                mx: 0.5,
              }}
            >
              {'•'}
            </Typography>
          )}
        </React.Fragment>
      ))}
    </OverflowAwareText>
  );
}

export function ChildCompanyBadge({ order }: OrderDispatchCardFragment) {
  const { userStore } = useStores();
  const { userCompanies } = userStore;
  const orderAccountCompanyId = order.account?.accountBasicCompanyId;
  const isChildCompanyOrder = userCompanies.find(
    (company) => company.id === orderAccountCompanyId,
  );

  if (userCompanies.length > 1) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          mt: 0.25,
          maxWidth: '120px',
        }}
      >
        <MUIChip
          label={
            isChildCompanyOrder
              ? order.company?.legalName
              : userStore.currentCompanies[0].legalName
          }
          size="small"
          sx={{
            backgroundColor: theme.brandV2.colors.treadGray8,
            border: `1px solid ${theme.brandV2.colors.treadGray7}`,
            color: theme.brandV2.colors.treadBlack,
            fontSize: '12px',
            fontWeight: 600,
            borderRadius: '4px',
          }}
        />
      </Box>
    );
  }

  return null;
}

interface StatusProps extends OrderDispatchCardFragment {
  onAccept: () => void;
  onOrderStateChange: (nextOrderState: OrderState) => Promise<void>;
  onReject: () => void;
}

type AvailableAction = {
  color: ButtonProps['color'];
  icon?: ReactNode;
  isDisabled?: boolean;
  label: string;
  onClick: () => void;
};

export const Status = observer(
  ({ onAccept, onOrderStateChange, onReject, order }: StatusProps) => {
    const { ordersDispatchStore, userStore } = useStores();
    const { canDeleteOrder } = userStore.getPermissions();

    const orderCTAs = useMemo(() => {
      const isPendingRequest = order.state === OrderState.PENDING_REQUEST;

      if (isPendingRequest) {
        return [
          {
            color: 'brandV2Green',
            isDisabled: ordersDispatchStore.acceptingOrRejectingOrderId === order.id,
            label: t('actions.accept'),
            onClick: onAccept,
          } as AvailableAction,
          {
            color: 'brandV2Red',
            isDisabled: ordersDispatchStore.acceptingOrRejectingOrderId === order.id,
            label: t('actions.decline'),
            onClick: onReject,
          } as AvailableAction,
        ];
      }

      return null;
    }, [order.id, order.state, ordersDispatchStore.acceptingOrRejectingOrderId]);

    const transitionableStates: OrderState[] = useMemo(() => {
      const { editable, state } = order;
      const availableNextStates: OrderState[] = [];

      const isCancellable =
        editable && !doneOrderStates.includes(state) && canDeleteOrder;
      const isCompletable = isCancellable && state !== OrderState.CREATED;

      if (isCancellable) {
        availableNextStates.push(OrderState.CANCELED);
      }

      if (isCompletable) {
        availableNextStates.push(OrderState.COMPLETED);
      }

      return availableNextStates;
    }, [order.editable, order.state, canDeleteOrder]);

    const isTransitioningToStatus = useMemo(() => {
      if (ordersDispatchStore.isCancellingOrder) {
        return OrderState.CANCELED;
      }

      if (ordersDispatchStore.isCompletingOrder) {
        return OrderState.COMPLETED;
      }
    }, [ordersDispatchStore.isCancellingOrder, ordersDispatchStore.isCompletingOrder]);

    if (orderCTAs) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          {orderCTAs.map((cta) => (
            <SmallButton
              key={cta.label}
              color={cta.color}
              disabled={cta.isDisabled}
              onClick={(event) => {
                event.stopPropagation();
                cta.onClick();
              }}
              startIcon={cta.icon}
              sx={{
                [`&.${buttonBaseClasses.root}`]: {
                  height: '24px',
                  px: 0.5,
                  [`&.${buttonBaseClasses.disabled}`]: { pointerEvents: 'auto' },
                  [`.${svgIconClasses.root}`]: { fontSize: '16px' },
                },
              }}
            >
              {cta.label}
            </SmallButton>
          ))}
        </Box>
      );
    }

    return (
      <Box>
        <OrderStatusTransitioner
          closeOnTransitionComplete
          isTransitioningToStatus={isTransitioningToStatus}
          onTransitionToStatus={onOrderStateChange}
          order={order}
          transitionableStates={transitionableStates}
        />
      </Box>
    );
  },
);

interface BulkAssignMultipleCTAProps extends OrderDispatchCardFragment {
  onClick?: () => void;
}

export const BulkAssignMultipleCTA = observer(
  ({ onClick, order }: BulkAssignMultipleCTAProps) => {
    const { ordersDispatchStore, userStore } = useStores();
    const isDone = doneOrderStates.includes(order.state);

    const shouldShowCTA = useMemo(() => {
      if (!onClick || isDone) {
        return false;
      }

      const orderJobs = ordersDispatchStore.orderJobs.get(order.id) ?? [];
      const orderHasAssignableJobs = orderJobs.some((job) => isJobAssignable(job));

      return orderHasAssignableJobs;
    }, [JSON.stringify(ordersDispatchStore.orderJobs.get(order.id)), isDone, onClick]);

    if (!shouldShowCTA || userStore.isViewOnlyUser) {
      return null;
    }

    return (
      <BasicTooltip title={t('dispatch.order.bulk_assign_order')}>
        <Box
          sx={{
            display: 'inline-block',
            height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
          }}
        >
          <SmallButton
            color="brandV2Orange"
            variant="text"
            startIcon={<PeopleOutlineOutlined />}
            onClick={(event) => {
              event.stopPropagation();
              onClick?.();
            }}
            sx={{
              [`&.${buttonBaseClasses.root}`]: {
                border: 'solid 1px transparent',
                borderRadius: '50%',
                height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                minWidth: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                p: 0,
                width: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                '&:hover': { borderColor: theme.brandV2.colors.treadOrange },
                [`.${buttonClasses.startIcon}`]: { mx: 0 },
              },
            }}
          />
        </Box>
      </BasicTooltip>
    );
  },
);

interface EditOrderCTAProps extends OrderDispatchCardFragment {
  onClick: () => void;
}

export const EditOrderCTA = observer(({ onClick, order }: EditOrderCTAProps) => {
  const { userStore } = useStores();
  const { canEditOrder } = userStore.getPermissions();

  const isEditable = order.editable && canEditOrder;

  if (!isEditable) {
    return null;
  }

  return (
    <BasicTooltip title={t('dispatch.order.edit_order')}>
      <Box
        sx={{
          display: 'inline-block',
          height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
        }}
      >
        <SmallButton
          color="brandV2Orange"
          variant="text"
          startIcon={<EditOutlined />}
          onClick={(event) => {
            event.stopPropagation();
            onClick();
          }}
          sx={{
            [`&.${buttonBaseClasses.root}`]: {
              border: 'solid 1px transparent',
              borderRadius: '50%',
              height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
              minWidth: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
              p: 0,
              width: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
              '&:hover': { borderColor: theme.brandV2.colors.treadOrange },
              [`.${buttonClasses.startIcon}`]: { mx: 0 },
            },
          }}
        />
      </Box>
    </BasicTooltip>
  );
});

interface ThreeDotsMenuProps extends OrderDispatchCardFragment {
  onCancelClick: () => void;
  onCloneOrderClick: () => void;
  onTextAllDriversClick: () => void;
}

export const ThreeDotsMenu = observer(
  ({
    onCancelClick,
    onCloneOrderClick,
    onTextAllDriversClick,
    order,
  }: ThreeDotsMenuProps) => {
    const { userStore } = useStores();
    const { canCreateOrder, canCreateJob, canDeleteOrder } = userStore.getPermissions();
    const menuHandler = useRef<MenuHandler>(null);

    const { editable, state } = order;

    const isCloneable = editable && canCreateOrder;
    const isDone = doneOrderStates.includes(state);

    const isCancellable = editable && !isDone && canDeleteOrder;
    const canAddNewJobs = editable && !isDone && canCreateJob;
    const canTextDrivers = editable && !isDone;

    const availableActions = useMemo(() => {
      const actions = [];

      if (canTextDrivers) {
        actions.push({
          key: 'text_all_drivers',
          icon: <ChatOutlined />,
          label: `${t('dispatch.order.text_all_drivers')}`,
          onClick: onTextAllDriversClick,
        });
      }

      if (isCloneable) {
        actions.push({
          key: 'clone_order',
          icon: <FileCopyOutlined />,
          label: `${t('dispatch.order.clone_order')}`,
          onClick: onCloneOrderClick,
        });
      }

      if (isCancellable) {
        actions.push({
          key: 'cancel_order',
          icon: <CancelOutlined />,
          label: t('dispatch.order.cancel_order'),
          onClick: onCancelClick,
        });
      }

      return actions;
    }, [isCloneable, canAddNewJobs, canTextDrivers, isCancellable]);

    const [[cancelAction], otherActions] = _.partition(availableActions, (action) => {
      return action.key === 'cancel_order';
    });

    if (!availableActions.length) {
      return null;
    }

    return (
      <Menu
        ref={menuHandler}
        menuTrigger={
          <SmallButton
            color="brandV2TreadGray3"
            variant="text"
            startIcon={<MoreHoriz />}
            sx={{
              [`&.${buttonBaseClasses.root}`]: {
                border: 'solid 1px transparent',
                borderRadius: '50%',
                height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                minWidth: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                p: 0,
                width: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                '&:hover': { borderColor: theme.brandV2.colors.treadGray3 },
                [`.${buttonClasses.startIcon}`]: { mx: 0 },
              },
            }}
          />
        }
      >
        {otherActions.map((action) => (
          <MenuItem
            key={action.key}
            onClick={(event) => {
              event.stopPropagation();
              action.onClick();
              menuHandler.current?.onClose?.();
            }}
          >
            <Box alignItems="center" display="flex" width="100%" gap={1}>
              {cloneElement(action.icon, {
                sx: { fontSize: '16px', color: theme.brandV2.colors.treadBlack },
              })}

              <Typography color={theme.brandV2.colors.treadBlack} fontSize="12px">
                {action.label}
              </Typography>
            </Box>
          </MenuItem>
        ))}

        {Boolean(otherActions.length && cancelAction) && <Divider sx={{ mx: 1 }} />}

        {Boolean(cancelAction) && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              cancelAction.onClick();
              menuHandler.current?.onClose?.();
            }}
          >
            <Box alignItems="center" display="flex" width="100%" gap={1}>
              {cloneElement(cancelAction.icon, {
                sx: { fontSize: '16px', color: theme.brandV2.colors.treadBlack },
              })}

              <Typography color={theme.brandV2.colors.treadBlack} fontSize="12px">
                {cancelAction.label}
              </Typography>
            </Box>
          </MenuItem>
        )}
      </Menu>
    );
  },
);

export function ServiceType({ order }: OrderDispatchCardFragment) {
  if (!order.service?.name) {
    return null;
  }

  return (
    <OverflowAwareText sx={{ fontSize: '14px' }}>
      {t('form_fields.service')}:{' '}
      <Typography
        component="span"
        sx={{ fontSize: 'inherit', color: theme.brandV2.colors.treadGray3 }}
      >
        {order.service.name}
      </Typography>
    </OverflowAwareText>
  );
}

export function Requester({ order }: OrderDispatchCardFragment) {
  const requester = order.requester?.fullName;

  if (!requester) {
    return null;
  }

  return (
    <OverflowAwareText sx={{ fontSize: '14px' }}>
      {t('dispatch.dispatch_v3.requestor')}:{' '}
      <Typography
        component="span"
        sx={{ fontSize: 'inherit', color: theme.brandV2.colors.treadGray3 }}
      >
        {requester}
      </Typography>
    </OverflowAwareText>
  );
}

export function StartTime({ order }: OrderDispatchCardFragment) {
  if (!order.loadAt) {
    return null;
  }

  const date = order.loadAt.format('h:mm A ddd MMM-DD-YYYY');

  return (
    <OverflowAwareText sx={{ fontSize: '14px' }}>
      {t('dispatch.dispatch_v2.start')}:{' '}
      <Typography
        component="span"
        sx={{ fontSize: 'inherit', color: theme.brandV2.colors.treadGray3 }}
      >
        {date}
      </Typography>
    </OverflowAwareText>
  );
}

export function EquipmentType({ order }: OrderDispatchCardFragment) {
  if (!order.equipmentType?.name) {
    return null;
  }

  return (
    <OverflowAwareText sx={{ fontSize: '14px' }}>
      {t('dispatch.order.order_details_v2.truck_type')}:{' '}
      <Typography
        component="span"
        sx={{ fontSize: 'inherit', color: theme.brandV2.colors.treadGray3 }}
      >
        {order.equipmentType.name}
      </Typography>
    </OverflowAwareText>
  );
}

interface BaseNotesComponentProps {
  text?: string;
  title: string;
}

function BaseNotesComponent({ text, title }: BaseNotesComponentProps) {
  if (!text) {
    return null;
  }

  return (
    <BasicTooltip
      placement="bottom-start"
      title={`${title}: ${text}`}
      slotProps={{
        tooltip: { sx: { [`&.${tooltipClasses.tooltip}`]: { whiteSpace: 'pre-line' } } },
      }}
    >
      <OverflowAwareText sx={{ fontSize: '14px' }}>
        {title}:{' '}
        <Typography
          component="span"
          sx={{ fontSize: 'inherit', color: theme.brandV2.colors.treadGray3 }}
        >
          {text}
        </Typography>
      </OverflowAwareText>
    </BasicTooltip>
  );
}

export function OrderNotes({ order }: OrderDispatchCardFragment) {
  const text = order.orderNotes || order.project?.orderNotes || undefined;

  return <BaseNotesComponent text={text} title={t('form_fields.order')} />;
}

export function InternalNotes({ order }: OrderDispatchCardFragment) {
  const text = order.internalNotes || order.project?.internalNotes || undefined;

  return <BaseNotesComponent text={text} title={t('form_fields.internal')} />;
}

export function JobNotes({ order }: OrderDispatchCardFragment) {
  const text = order.jobNotes || order.project?.jobNotes || undefined;

  return <BaseNotesComponent text={text} title={t('form_fields.job')} />;
}

export function CycleTime({ order }: OrderDispatchCardFragment) {
  if (!order.loadCycleAvg) {
    return null;
  }

  const hours = Math.floor(
    dayjs.duration({ minutes: order.loadCycleAvg }).asHours(),
  ).toString();
  const minutes = dayjs
    .duration({ minutes: order.loadCycleAvg % 60 })
    .asMinutes()
    .toString();
  const formatted = `${hours}hr ${minutes}m`;

  return (
    <OverflowAwareText sx={{ fontSize: '14px' }}>
      {t('dispatch.dispatch_v2.cycle_time')}:{' '}
      <Typography
        component="span"
        sx={{ fontSize: 'inherit', color: theme.brandV2.colors.treadGray3 }}
      >
        {formatted}
      </Typography>
    </OverflowAwareText>
  );
}

interface AlertsProps extends OrderDispatchCardFragment {
  nonRoutableAlertsFeatureFlagEnabled?: boolean;
}

export function Alerts({ nonRoutableAlertsFeatureFlagEnabled, order }: AlertsProps) {
  let hasNonRoutableSites = false;

  if (nonRoutableAlertsFeatureFlagEnabled && order.waypoints) {
    hasNonRoutableSites = order.waypoints.some((waypoint) => {
      return !waypoint.siteNested?.routable;
    });
  }

  let alertMessage = '';

  if (hasNonRoutableSites) {
    alertMessage = t('common.address_confirmation_required');
  } else if (order.actionRequired) {
    alertMessage = t('dispatch.order.pending_jobs');
  }

  if (!alertMessage) {
    return null;
  }

  return (
    <BasicTooltip title={alertMessage}>
      <WarningRounded sx={{ color: theme.brandV2.colors.treadOrange }} />
    </BasicTooltip>
  );
}

export function TrucksSummary({ order }: OrderDispatchCardFragment) {
  const count = order.orderSummary?.acceptedJobsCount ?? 0;
  const total = order.orderSummary?.jobsCount ?? 0;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Summary
        count={count}
        total={total}
        description={t('dispatch.dispatch_v3.trucks_summary.label', {
          num: formatNumber(total),
        })}
        descriptionSx={{ mb: 0.5, fontWeight: 500, fontSize: '14px' }}
      />

      <OverflowAwareText
        sx={{ fontSize: '12px', color: theme.brandV2.colors.treadGray3 }}
      >
        {t('dispatch.dispatch_v3.trucks_summary.description', {
          num: formatNumber(count),
          total: formatNumber(total),
        })}
      </OverflowAwareText>
    </Box>
  );
}

export function DeliveredSummary({ order }: OrderDispatchCardFragment) {
  const { userStore } = useStores();

  let deliveredCount = 0;
  let deliveredTotal = 0;

  if (order.orderSummary?.deliveredQuantities) {
    [deliveredCount, deliveredTotal] = order.orderSummary.deliveredQuantities.reduce(
      ([countSum, totalSum], entry) => {
        return [countSum + Number(entry.delivered), totalSum + Number(entry.total)];
      },
      [0, 0],
    );
  }

  let total = order.quantity ?? 0;
  const isForLoggedInCompany = order.company?.id === userStore.userCompany.id;

  if (!isForLoggedInCompany) {
    total = deliveredTotal;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 0.5, minWidth: 72 }}>
        <Typography
          sx={{
            color: theme.brandV2.colors.treadBlack,
            fontSize: '14px',
            fontWeight: 500,
            mb: '2px',
            whiteSpace: 'nowrap',
          }}
        >
          {t('dispatch.dispatch_v3.delivered_summary.label', {
            num: formatNumber(total),
          })}
        </Typography>

        <Box
          sx={{
            bgcolor: theme.brandV2.colors.treadGray7,
            borderRadius: '2px',
            height: '4px',
            overflow: 'hidden',
            position: 'relative',
            width: 72,
            '&:before': {
              backgroundColor: theme.brandV2.colors.treadBlue,
              bottom: 0,
              content: "''",
              left: 0,
              position: 'absolute',
              top: 0,
              width: `calc((${deliveredCount} / ${deliveredTotal || 1}) *  100%)`,
            },
          }}
        />
      </Box>

      <OverflowAwareText
        sx={{ fontSize: '12px', color: theme.brandV2.colors.treadGray3 }}
      >
        {t('dispatch.dispatch_v3.delivered_summary.description', {
          num: formatNumber(deliveredCount),
          total: formatNumber(deliveredTotal),
        })}
      </OverflowAwareText>
    </Box>
  );
}

interface BaseWaypointComponentProps extends OrderDispatchCardFragment {
  waypointType: Extract<WaypointType, WaypointType.PICKUP | WaypointType.DROP_OFF>;
}

function BaseWaypointComponent({ order, waypointType }: BaseWaypointComponentProps) {
  const props = useMemo(() => {
    const isPickup = waypointType === WaypointType.PICKUP;
    const index = isPickup ? 0 : 1;
    const waypoint = extractWaypointData(order.waypoints?.[index]);

    if (!waypoint) {
      return null;
    }

    if (isPickup) {
      return { waypoint, label: t('form_fields.pickup') };
    }

    return { waypoint, label: t('form_fields.dropoff') };
  }, [order, waypointType]);

  if (!props) {
    return null;
  }

  return (
    <OverflowAwareText
      sx={{ display: 'flex', alignItems: 'center', gap: 0.5, fontSize: '14px' }}
    >
      {props.label}: <GeofenceStartAdornment geofenceType={props.waypoint.geofenceType} />
      <Typography
        component="span"
        sx={{ fontSize: 'inherit', color: theme.brandV2.colors.treadGray3 }}
      >
        {props.waypoint.name}
      </Typography>
    </OverflowAwareText>
  );
}

export function PickupWaypoint({ order }: OrderDispatchCardFragment) {
  return <BaseWaypointComponent order={order} waypointType={WaypointType.PICKUP} />;
}

export function DropffWaypoint({ order }: OrderDispatchCardFragment) {
  return <BaseWaypointComponent order={order} waypointType={WaypointType.DROP_OFF} />;
}
