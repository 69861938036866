export enum MarkerType {
  truck = 'truck',
  site = 'site',
  moving_site = 'moving_site',
  ping = 'ping',
}

export enum GeofenceType {
  polygon = 'polygon',
  circle = 'circle',
}

export const HIGHLIGHT_MARKER_RADIUS = 12;
export const ROUTE_MARKER_RADIUS = 8;

export const GOOGLE_MAPS_LOADER_OPTIONS = {
  id: 'google-map-script',
  googleMapsApiKey: import.meta.env.TREAD__GOOGLE_MAPS_API_KEY,
  version: '3.58',
};
