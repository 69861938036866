import AddIcon from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import styled from '@mui/system/styled';
import { AddOnRateType } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  FieldValues,
  useFieldArray,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';

import { ModalDialog, ModalDialogHandler } from '~components/Dialog/ModalDialog';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import {
  OverflowAwareText,
  SmallButton,
} from '~components/Order/ordersDispatchStyledComponents';
import { PhaseSelection } from '~components/Order/PhaseSelection';
import { FeatureFlags } from '~constants/featureFlags';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import { AddOn, AddOnCharge, useAddOns } from '~hooks/useAddOns';
import { DriverBasic } from '~hooks/useDrivers';
import { BasicEquipment, EquipmentTypeBasic, useEquipment } from '~hooks/useEquipment';
import { useOrders, useOrdersDispatch } from '~hooks/useOrders';
import { Phase } from '~hooks/useProjects/models';
import { ResourceUsageLog } from '~hooks/useResourceUsageLogs/models';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { Nullable } from '~types/Nullable';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';
import { usePrevious } from '~utils/hooks/usePrevious';

import { AddOnSelectOption } from '../../DriverPay/AddOnChargesTableRows';
import { appendAddOnRateTypeToName, schemaHelpers } from '../../DriverPay/helpers';

export type Charge = {
  id: Nullable<string>;
  description: string;
  quantity: number;
  phase?: Nullable<Phase>;
  phaseLabel?: string;
  type?: 'equipment' | 'driver';
};
interface ChargesTableProps {
  sx?: SxProps;
  isEditing: boolean;
  resourceUsageLogs: ResourceUsageLog[];
  addOns: AddOnCharge[];
  phasesOptions: Phase[];
  driver: DriverBasic;
  isJobLevel?: boolean;
  orderId?: string;
  reloadOrderPhases?: () => void;
}

const deriveChargeFromResourceUsageLog = (resourceUsageLog: ResourceUsageLog): Charge => {
  return {
    id: resourceUsageLog.id ?? null,
    description: resourceUsageLog.equipment
      ? ` ${resourceUsageLog.equipment.name} - ${resourceUsageLog.equipment.externalId || ''}`
      : resourceUsageLog.driver
        ? `${resourceUsageLog.driver.fullName}${resourceUsageLog.driver.companyShare ? '-' + resourceUsageLog.driver.companyShare.senderCompany.legalName : ''}`
        : '',

    quantity: resourceUsageLog.usageMinutes / 60,
    phase: resourceUsageLog.phase,
    phaseLabel: resourceUsageLog.phase
      ? `${resourceUsageLog.phase.name + ' (' + resourceUsageLog.phase.code + ')'}`
      : '-',
    type: resourceUsageLog.equipment ? 'equipment' : 'driver',
  };
};

const deriveChargeFromAddOn = (addon: AddOnCharge): Charge => {
  return {
    id: addon.id ?? '',
    description: `AddOn: ${addon.name}`,
    quantity: addon.addOnRateType === AddOnRateType.RATE_FOR_EACH ? addon.quantity : 0,
    phaseLabel: '-',
  };
};

const TableRowStyled = styled(TableRow)<TableRowProps>(() => ({
  '&:not(.MuiTableBody-root tr:last-child)': {
    borderBottom: `solid 1px ${theme.brandV2.colors.treadGray7}`,
  },
}));

const TableCellStyled = styled(TableCell, {
  shouldForwardProp: (propName) => propName !== 'isHeader',
})<TableCellProps & { isHeader?: boolean }>(({ isHeader }) => ({
  borderBottom: 0,
  color: theme.brandV2.colors.treadBlack,
  fontSize: '12px',
  fontWeight: isHeader ? 600 : 400,
  padding: theme.spacing(),
  whiteSpace: 'nowrap',
}));

const ResourceUsageLogToCharges = ({
  resourceUsageLogs,
  isEditing,
  phasesOptions,
  fields,
  update,
  remove,
}: {
  resourceUsageLogs: ResourceUsageLog[];
  isEditing: boolean;
  phasesOptions: Phase[];
  fields: FieldValues[];
  update: UseFieldArrayUpdate<FieldValues, 'resourceUsageLogs'>;
  remove: UseFieldArrayRemove;
}) => {
  const showPhaseColumn = useFeatureFlag({
    featureFlagKey: FeatureFlags.showPhaseColumninChargesTable,
  });
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { userStore, companyAssetsStore } = useStores();
  const { getEquipmentByCompanyId, isLoading } = useEquipment();
  const equipmentOptions = companyAssetsStore.equipment;

  const watchedResourceUsageLogs = useWatch({ control, name: 'resourceUsageLogs' });

  const handleRemoveAddOnClick = useCallback(
    (index: number) => {
      const resourceUsageLog = watchedResourceUsageLogs?.[index];

      if (resourceUsageLog?.id) {
        update(index, { ...resourceUsageLog, _destroy: 1 });
      } else {
        remove(index);
      }
    },
    [update, watchedResourceUsageLogs],
  );

  useEffect(() => {
    getEquipmentByCompanyId({ companyId: userStore.userCompany.id });
  }, [userStore.userCompany.id]);

  const charges = useMemo(() => {
    return resourceUsageLogs.map((resourceUsageLog) => {
      return deriveChargeFromResourceUsageLog(resourceUsageLog);
    });
  }, [resourceUsageLogs]);

  if (isEditing) {
    return fields?.map((resource, index) => {
      const isDeleted = watchedResourceUsageLogs?.[index]?._destroy === 1;

      if (isDeleted) {
        return null;
      }
      const isEquipment =
        resourceUsageLogs[index]?.equipment?.id || resource.type === 'equipment';

      return (
        <TableRowStyled key={resource.id}>
          <TableCellStyled>{isEquipment ? 'Equipment' : 'Driver'}</TableCellStyled>

          <TableCellStyled>
            {!isEquipment ? (
              <OverflowAwareText>{resource.driver.name}</OverflowAwareText>
            ) : (
              <AutocompleteFormField
                clearable
                control={control}
                loading={isLoading}
                errors={errors}
                getLabel={(item) => item.name}
                getValue={(item) => item.id}
                isRequired
                list={equipmentOptions}
                name={`resourceUsageLogs[${index}].equipment`}
                sx={{
                  fontSize: '12px',
                  '& .MuiAutocomplete-inputRoot': { fontSize: '12px', width: '100%' },
                  '& .MuiAutocomplete-endAdornment': {
                    right: '5px !important',
                    top: 'calc(50% - 13px)',
                    width: 'fit-content',
                  },
                }}
              />
            )}
          </TableCellStyled>
          <TableCellStyled>
            <TextFormField
              control={control}
              errors={errors}
              name={`resourceUsageLogs[${index}].quantity`}
            />
          </TableCellStyled>
          {showPhaseColumn ? (
            <TableCellStyled>
              {phasesOptions.length > 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                >
                  <PhaseSelection
                    phaseOptions={phasesOptions.map((phase) => ({
                      id: phase.id,
                      name: phase.name,
                      code: phase.code,
                    }))}
                    canEdit={false}
                    hasLabels={false}
                    canAdd={false}
                    selectedPhase={watchedResourceUsageLogs[index]?.phase}
                    onChange={(phase) => {
                      update(index, { ...watchedResourceUsageLogs[index], phase: phase });
                    }}
                    inputStyle={{
                      width: '100%',
                    }}
                  />
                </Box>
              ) : (
                <OverflowAwareText>{charges[index]?.phaseLabel}</OverflowAwareText>
              )}
            </TableCellStyled>
          ) : (
            <TableCellStyled />
          )}

          <TableCellStyled />
          <TableCellStyled
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <IconButton
              size={'medium'}
              onClick={() => {
                handleRemoveAddOnClick(index);
              }}
              sx={{
                justifyContent: 'flex-end',
                display: 'flex',
              }}
            >
              <Close
                fontSize={'medium'}
                sx={{ color: theme.brandV2.colors.treadGray3 }}
              />
            </IconButton>
          </TableCellStyled>
        </TableRowStyled>
      );
    });
  }

  return charges?.map((charge, index) => {
    const isEquipment = resourceUsageLogs[index].equipment?.id;
    return (
      <TableRowStyled key={charge.id}>
        <TableCellStyled>
          {' '}
          <OverflowAwareText>
            {isEquipment
              ? t('approvals.driver_pay.charges_table.equipment')
              : t('approvals.driver_pay.charges_table.driver')}{' '}
          </OverflowAwareText>
        </TableCellStyled>

        <TableCellStyled>
          <OverflowAwareText>{charge.description}</OverflowAwareText>
        </TableCellStyled>
        <TableCellStyled>
          <OverflowAwareText>{charge.quantity.toFixed(2)}</OverflowAwareText>
        </TableCellStyled>
        {showPhaseColumn ? (
          <TableCellStyled>
            <OverflowAwareText>{charge?.phaseLabel}</OverflowAwareText>
          </TableCellStyled>
        ) : (
          <TableCellStyled />
        )}
      </TableRowStyled>
    );
  });
};

const AddOnsToCharges = ({
  addOns,
  isEditing,
  fields,
  update,
  remove,
}: {
  addOns: AddOnCharge[];
  isEditing: boolean;
  fields: FieldValues[];
  update: UseFieldArrayUpdate<FieldValues, 'addOnCharges'>;
  remove: UseFieldArrayRemove;
}) => {
  const showPhaseColumn = useFeatureFlag({
    featureFlagKey: FeatureFlags.showPhaseColumninChargesTable,
  });
  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { addOnsStore, userStore } = useStores();
  const companyId = userStore.userCompany.id;

  const watchedAddOnCharges = useWatch({ control, name: 'addOnCharges' });
  const previousWatchedAddOnCharges = usePrevious(watchedAddOnCharges);

  const { getAllAddOns } = useAddOns();

  const handleRemoveAddOnClick = useCallback(
    (index: number) => {
      const addOnChargeToDelete = watchedAddOnCharges?.[index];

      if (addOnChargeToDelete?.id) {
        update(index, { ...addOnChargeToDelete, _destroy: 1 });
      } else {
        remove(index);
      }
    },
    [update, watchedAddOnCharges],
  );

  const charges = useMemo(() => {
    return addOns.map((addOn) => {
      return deriveChargeFromAddOn(addOn);
    });
  }, [addOns]);

  const addOnSelectOptions = useMemo(() => {
    const options = addOnsStore.addOns.reduce((options, addOn) => {
      options.push({
        id: String(addOn.id),
        name: appendAddOnRateTypeToName(addOn.name, addOn.addOnRateType),
      });

      return options;
    }, [] as AddOnSelectOption[]);

    return options;
  }, [addOnsStore.addOns]);

  const addOnByAddOnId = useMemo(() => {
    const addOnByAddOnId = addOnsStore.addOns.reduce(
      (acc, addOn) => {
        acc[addOn.id ?? ''] = addOn;
        return acc;
      },
      {} as Record<string, AddOn>,
    );
    return addOnByAddOnId;
  }, [addOnsStore.addOns]);

  useEffect(() => {
    if (!addOnsStore.addOns.length) {
      getAllAddOns(companyId);
    }
  }, [companyId, addOnsStore.addOns]);

  useEffect(() => {
    if (!watchedAddOnCharges || !previousWatchedAddOnCharges) {
      return;
    }
    watchedAddOnCharges.forEach(
      (current: { addOn: { id: any }; id: Nullable<string> }, index: number) => {
        const currentAddOnId = current.addOn?.id;
        const previousAddOnIn = previousWatchedAddOnCharges[index]?.addOn?.id;
        const selectedAddOnDidChange =
          currentAddOnId && currentAddOnId !== previousAddOnIn;
        // If the selected add-on did change then use the newly selected add-on values
        // As the default values for the add-on charge DTO
        if (selectedAddOnDidChange && addOnByAddOnId[currentAddOnId]) {
          const newlySelectedAddOn = addOnByAddOnId[currentAddOnId];
          const isRateForEach =
            newlySelectedAddOn.addOnRateType === AddOnRateType.RATE_FOR_EACH;
          const addOnCharge = new AddOnCharge(
            current.id,
            newlySelectedAddOn.name,
            newlySelectedAddOn.percentage,
            isRateForEach ? newlySelectedAddOn.quantity : 0,
            newlySelectedAddOn.rate,
            0,
            newlySelectedAddOn.addOnRateType,
            newlySelectedAddOn.externalId,
            { id: currentAddOnId },
          );
          update(index, schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge));
          clearErrors([`addOnCharges.${index}.quantity`]);
          clearErrors([`addOnCharges.${index}.addOn`]);
        }
      },
    );
  }, [
    watchedAddOnCharges,
    previousWatchedAddOnCharges,
    update,
    clearErrors,
    addOnByAddOnId,
  ]);

  if (isEditing) {
    return (
      <>
        {fields.map((addOn, index) => {
          const isDeleted = watchedAddOnCharges?.[index]?._destroy === 1;

          if (isDeleted) {
            return null;
          }

          const editedAddOnCharge = watchedAddOnCharges?.[index];
          const isRateForEach =
            editedAddOnCharge?.addOnRateType === AddOnRateType.RATE_FOR_EACH;

          return (
            <TableRowStyled key={addOn.id}>
              <TableCellStyled>
                {' '}
                <OverflowAwareText>
                  {t('approvals.driver_pay.charges_table.add_on')}{' '}
                </OverflowAwareText>
              </TableCellStyled>
              <TableCellStyled>
                <AutocompleteFormField
                  clearable
                  control={control}
                  errors={errors}
                  getLabel={(item) => item.name}
                  getValue={(item) => item.id}
                  list={addOnSelectOptions}
                  name={`addOnCharges[${index}].addOn`}
                  sx={{
                    fontSize: '12px',
                    '& .MuiAutocomplete-inputRoot': { fontSize: '12px', width: '100%' },
                    '& .MuiAutocomplete-endAdornment': {
                      right: '5px !important',
                      top: 'calc(50% - 13px)',
                      width: 'fit-content',
                    },
                  }}
                />
              </TableCellStyled>
              <TableCellStyled>
                {isRateForEach && (
                  <TextFormField
                    control={control}
                    errors={errors}
                    name={`addOnCharges[${index}].quantity`}
                  />
                )}
              </TableCellStyled>
              <TableCellStyled />
              <TableCellStyled />
              <TableCellStyled
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  size={'medium'}
                  onClick={() => handleRemoveAddOnClick(index)}
                  sx={{
                    justifyContent: 'flex-end',
                    display: 'flex',
                  }}
                >
                  <Close
                    fontSize={'medium'}
                    sx={{ color: theme.brandV2.colors.treadGray3 }}
                  />
                </IconButton>
              </TableCellStyled>
            </TableRowStyled>
          );
        })}
      </>
    );
  }

  return charges?.map((charge, index) => {
    const isRateForEach = addOns[index].addOnRateType === AddOnRateType.RATE_FOR_EACH;
    return (
      <TableRowStyled key={charge.id}>
        <TableCellStyled>
          {' '}
          <OverflowAwareText>
            {t('approvals.driver_pay.charges_table.add_on')}{' '}
          </OverflowAwareText>
        </TableCellStyled>

        <TableCellStyled>
          <OverflowAwareText>{charge.description}</OverflowAwareText>
        </TableCellStyled>
        <TableCellStyled>
          {isRateForEach ? (
            <OverflowAwareText>{charge.quantity.toFixed(2)}</OverflowAwareText>
          ) : null}
        </TableCellStyled>
        {showPhaseColumn ? (
          <TableCellStyled>
            <OverflowAwareText>{charge?.phaseLabel}</OverflowAwareText>
          </TableCellStyled>
        ) : (
          <TableCellStyled />
        )}
      </TableRowStyled>
    );
  });
};

export default function ChargesTable({
  sx,
  isEditing,
  resourceUsageLogs,
  addOns,
  phasesOptions,
  driver,
  isJobLevel = false,
  orderId,
  reloadOrderPhases,
}: ChargesTableProps) {
  const createPhaseModalRef = useRef<ModalDialogHandler>(null);

  const showTableHeader = isEditing || resourceUsageLogs.length > 0 || addOns.length > 0;

  const showPhaseColumn = useFeatureFlag({
    featureFlagKey: FeatureFlags.showPhaseColumninChargesTable,
  });
  const [isCreatingPhase, setIsCreatingPhase] = useState(false);
  const [createPhaseError, setCreatePhaseError] = useState<string | null>(null);
  const { addNewPhaseToOrder } = useOrders();

  const {
    control: phaseFormControl,
    formState: { isDirty, errors },
    watch: watchPhase,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      name: null,
      code: null,
    },
  });

  const watchedPhase = watchPhase();

  const validateNewPhase = (name: string, code: string) => {
    const existingPhase = phasesOptions.find(
      (phase) => phase.name === name && phase.code === code,
    );

    if (existingPhase) {
      return false;
    }

    return true;
  };

  const onCreatePhase = (data: any) => {
    if (orderId && reloadOrderPhases) {
      const isPhaseUnique = validateNewPhase(data.name, data.code);
      if (!isPhaseUnique) {
        setCreatePhaseError(t('approvals.driver_day.phase_is_not_unique'));
        return;
      }
      setIsCreatingPhase(true);

      addNewPhaseToOrder(orderId, data.name, data.code)
        .then(() => {
          createPhaseModalRef.current?.close();
          reset();
          reloadOrderPhases();
        })
        .finally(() => {
          setIsCreatingPhase(false);
        });
    }
  };

  const { control } = useFormContext();
  const {
    fields: addOnFields,
    append: appendAddOn,
    update: updateAddOn,
    remove: removeAddOn,
  } = useFieldArray({
    control,
    name: 'addOnCharges',
  });
  const { companyAssetsStore, userStore } = useStores();
  const { getEquipmentByCompanyId } = useEquipment();
  useEffect(() => {
    getEquipmentByCompanyId({ companyId: userStore.userCompany.id });
  }, [userStore.userCompany.id]);
  const equipmentOptions = companyAssetsStore.equipment;

  const {
    fields: resourceLogsFields,
    append: appendResourceLog,
    update: updateResourceLog,
    remove: removeResourceLog,
  } = useFieldArray({
    control,
    name: 'resourceUsageLogs',
  });

  const handleAddAddOnClick = useCallback(() => {
    const addOnCharge = new AddOnCharge(
      null,
      '',
      0,
      0,
      0,
      0,
      AddOnRateType.RATE_FOR_EACH,
      null,
      { id: '' },
    );
    appendAddOn(schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge));
  }, [appendAddOn]);

  const handleAddResourceLogOnClick = useCallback(
    (type: 'driver' | 'equipment') => {
      const resourceUsageLog = new ResourceUsageLog(
        null,
        dayjs().tz(),
        dayjs().tz(),
        0,
        type === 'equipment'
          ? new BasicEquipment(
              equipmentOptions[0]?.id,
              equipmentOptions[0]?.name,
              equipmentOptions[0]?.externalId,
              new EquipmentTypeBasic(
                equipmentOptions[0]?.type?.id,
                equipmentOptions[0]?.type?.name,
                equipmentOptions[0]?.type?.system,
              ),
              null,
              equipmentOptions[0]?.licenseNumber,
              equipmentOptions[0]?.accountingId,
              equipmentOptions[0]?.zone,
            )
          : null,
        driver,
        null,
        '',
        '',
      );

      appendResourceLog({
        ...schemaHelpers.resourceUsageLogToResourceUsageLogDTO(resourceUsageLog, type),
        type,
      });
    },
    [appendResourceLog, equipmentOptions],
  );

  return (
    showTableHeader && (
      <TableContainer sx={{ px: 0.5, ...sx }}>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRowStyled>
              <TableCellStyled sx={{ width: '15%', fontWeight: 600 }}>
                {t('common.category')}
              </TableCellStyled>
              <TableCellStyled sx={{ width: '25%', fontWeight: 600 }}>
                {t('common.description')}
              </TableCellStyled>
              <TableCellStyled sx={{ width: '11%', fontWeight: 600 }}>
                {t('approvals.driver_day.add_on_charges_table.qty_header')}
              </TableCellStyled>
              {showPhaseColumn ? (
                <TableCellStyled sx={{ width: '42%', fontWeight: 600 }}>
                  {t('common.phase')}
                </TableCellStyled>
              ) : null}
            </TableRowStyled>
          </TableHead>

          <TableBody>
            <ResourceUsageLogToCharges
              resourceUsageLogs={resourceUsageLogs}
              isEditing={isEditing}
              phasesOptions={phasesOptions}
              fields={resourceLogsFields}
              update={updateResourceLog}
              remove={removeResourceLog}
            />
            <AddOnsToCharges
              addOns={addOns}
              isEditing={isEditing}
              fields={addOnFields}
              update={updateAddOn}
              remove={removeAddOn}
            />
            {isEditing && (
              <Box
                sx={{
                  p: 1.5,
                  pl: 1,
                  display: 'flex',
                  gap: 1,
                }}
              >
                <SmallButton
                  color="brandV2Yellow"
                  sx={{
                    '&.MuiButtonBase-root': {
                      height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
                    },
                  }}
                  onClick={() => {
                    handleAddAddOnClick();
                  }}
                  startIcon={<AddIcon />}
                >
                  {t('approvals.driver_pay.settlements.add_on')}
                </SmallButton>
                <SmallButton
                  color="brandV2Black"
                  variant="outlined"
                  sx={{
                    '&.MuiButtonBase-root': {
                      height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
                    },
                  }}
                  onClick={() => {
                    handleAddResourceLogOnClick('driver');
                  }}
                  startIcon={<AddIcon />}
                >
                  {t('approvals.driver_day.driver_hours')}
                </SmallButton>
                <SmallButton
                  color="brandV2Black"
                  variant="outlined"
                  sx={{
                    '&.MuiButtonBase-root': {
                      height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
                    },
                  }}
                  onClick={() => {
                    handleAddResourceLogOnClick('equipment');
                  }}
                  startIcon={<AddIcon />}
                >
                  {t('approvals.driver_day.equipment_hours')}
                </SmallButton>
                {isJobLevel ? (
                  <SmallButton
                    color="brandV2Black"
                    variant="outlined"
                    sx={{
                      '&.MuiButtonBase-root': {
                        height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
                      },
                    }}
                    onClick={() => {
                      createPhaseModalRef.current?.open();
                    }}
                  >
                    {t('approvals.driver_day.create_phase')}
                  </SmallButton>
                ) : null}

                <ModalDialog
                  ref={createPhaseModalRef}
                  title={t('approvals.driver_day.create_phase')}
                  content={
                    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextFormField
                          control={phaseFormControl}
                          name="name"
                          label={t('form_fields.phase_name') as string}
                          isRequired
                          errors={errors}
                          onFocus={() => {
                            setCreatePhaseError(null);
                          }}
                        />

                        <TextFormField
                          control={phaseFormControl}
                          name="code"
                          label={t('form_fields.phase_code') as string}
                          isRequired
                          errors={errors}
                          onFocus={() => {
                            setCreatePhaseError(null);
                          }}
                        />
                      </Box>
                      {createPhaseError && (
                        <Typography
                          variant="body2"
                          color={`${theme.brandV2.colors.treadRed}`}
                        >
                          {createPhaseError}
                        </Typography>
                      )}
                    </Box>
                  }
                  onClose={() => {
                    createPhaseModalRef.current?.close();
                    reset();
                    setCreatePhaseError(null);
                  }}
                  callBack={() => {
                    handleSubmit(onCreatePhase)();
                  }}
                  confirmButtonText={t('actions.create')}
                  confirmButtonColor="primary"
                  cancelButtonText={t('actions.cancel')}
                  confirmedDisabled={
                    !isDirty ||
                    !watchedPhase.name ||
                    !watchedPhase.code ||
                    createPhaseError
                  }
                  loading={isCreatingPhase}
                />
              </Box>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
}
