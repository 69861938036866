import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { OrderUnitOfMeasure } from '~constants/enums';
import { NameIdSchemaRequired } from '~constants/regexConst';
import { useOrders } from '~hooks/useOrders';
import { DeparsedOrderEstimate } from '~hooks/useOrders/models';
import { Service } from '~hooks/useServices';
import { nullableStringOrNumberIsValidNumber } from '~utils/utilFunctions';

import {
  serviceIsHiredTruck,
  serviceIsMaterialDelivery,
  serviceIsStandingHaul,
} from '../newOrderFormSchema';

type OrderEstimateArgs = DeparsedOrderEstimate;

export default function useOrderEstimate() {
  const form = useFormContext();

  const { getOrderEstimate } = useOrders();

  const watchedCycleTime = form.watch('cycleTime') as string | number | null;
  const watchedGrossCapacity = form.watch('grossCapacity') as string | number | null;
  const watchedJobTime = form.watch('jobTime') as string | number | null;
  const watchedQuantity = form.watch('quantity') as string | number | null;
  const watchedTruckCount = form.watch('truckCount') as string | number | null;
  const watchedService = form.watch('service') as Service | null;
  const watchedUoM = form.watch('unitOfMeasure') as NameIdSchemaRequired | null;

  const orderEstimateArgs: OrderEstimateArgs | null = useMemo(() => {
    const isValidCycleTime =
      nullableStringOrNumberIsValidNumber(watchedCycleTime) &&
      Number(watchedCycleTime) > 0;

    const isValidGrossCapacity =
      nullableStringOrNumberIsValidNumber(watchedGrossCapacity) &&
      Number(watchedGrossCapacity) > 0;

    const isValidJobTime =
      nullableStringOrNumberIsValidNumber(watchedJobTime) && Number(watchedJobTime) > 0;

    const isValidQuantity =
      nullableStringOrNumberIsValidNumber(watchedQuantity) && Number(watchedQuantity) > 0;

    const isValidTruckCount =
      nullableStringOrNumberIsValidNumber(watchedTruckCount) &&
      Number(watchedTruckCount) > 0;

    const isValidService =
      serviceIsStandingHaul(watchedService) ||
      serviceIsHiredTruck(watchedService) ||
      serviceIsMaterialDelivery(watchedService);

    const isTrucksBased =
      serviceIsStandingHaul(watchedService) || serviceIsHiredTruck(watchedService);

    const isValidUoM = Boolean(watchedUoM?.id);

    const canEstimate =
      isValidCycleTime &&
      isValidGrossCapacity &&
      isValidJobTime &&
      ((isTrucksBased && isValidTruckCount) ||
        (!isTrucksBased && isValidQuantity && isValidUoM)) &&
      isValidService;

    if (!canEstimate) {
      return null;
    }

    return {
      cycleTimeMinutes: Number(watchedCycleTime),
      equipmentCapacity: Number(watchedGrossCapacity),
      jobDurationMinutes: Number(watchedJobTime) * 60,
      quantity: isTrucksBased ? Number(watchedTruckCount) : Number(watchedQuantity),
      service: watchedService as Service,
      uom: watchedUoM?.id as OrderUnitOfMeasure,
    };
  }, [
    watchedCycleTime,
    watchedGrossCapacity,
    watchedJobTime,
    watchedQuantity,
    watchedTruckCount,
    watchedService,
    watchedUoM,
  ]);

  const estimateOrder = useCallback(async () => {
    if (!orderEstimateArgs) {
      return;
    }

    const estimate = await getOrderEstimate(orderEstimateArgs);

    if (!estimate) {
      return;
    }

    return {
      loadsPerTruck: estimate.loadsPerTruck,
      totalLoads: estimate.totalLoads,
      truckCount: estimate.truckQuantity,
      unitsPerHour: estimate.unitsPerHour,
    };
  }, [JSON.stringify(orderEstimateArgs)]);

  return { estimateOrder };
}
