import Add from '@mui/icons-material/Add';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import Edit from '@mui/icons-material/Edit';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { Account_Read_Nested, OrderState } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { t as $t, t } from 'i18next';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import DataGrid from '~components/DataGrid/DataGrid';
import { DataGridSmallButton } from '~components/DataGrid/DataGridSmallButton';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { DialogHeader } from '~components/Dialog/DialogHeader';
import { ModalDialog } from '~components/Dialog/ModalDialog';
import { SimpleMenu } from '~components/Menu/SimpleMenu';
import { Status as OrderStatus } from '~components/Order/Status';
import { StatusTabFilters } from '~components/StatusTabFilters/StatusTabFilters';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { OrderStatusFilter } from '~constants/enums';
import { FormStateChangeProps } from '~formsShared';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { Order, useOrders } from '~hooks/useOrders';
import { OrderEventType } from '~hooks/useOrders/useOrders';
import { usePermissions } from '~hooks/usePermissions';
import { useSites } from '~hooks/useSites';
import {
  activeOrderStates,
  doneOrderStates,
  externalOrderStates,
  inProgressOrderStates,
  pendingOrderStates,
} from '~pages/Dispatch/constants/orderStates';
import { useDispatchOrders } from '~pages/Dispatch/hooks/useDispatchOrders';
import { NewOrderForm } from '~pages/Sales/Orders/NewOrderForm';
import { PaginationLink } from '~services/pagination';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { Nullable } from '~types/Nullable';
import { dateFormat, minutesToHoursMins } from '~utils/dateTime';
import { isActionClicked } from '~utils/utilFunctions';

import { createSitesInline } from './helpers';
import { NewOrderFormSchemaInterface } from './newOrderFormSchema';

dayjs.extend(isSameOrBefore);

const OrdersDataGrid = observer(() => {
  const [currentFormDirty, setCurrentFormDirty] = useState<boolean>(false);
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState<boolean>(false);
  const [selectedOrderId, setSelectedOrderId] = useState<Nullable<string>>(null);

  const orderActionsRef = useRef<any>(null);
  const modalDialogRef = useRef<any>(null);
  const cloneModalDialogRef = useRef<any>(null);

  const {
    createOrder,
    getCompanyOrders,
    getOrdersByCompanyId,
    updateOrder,
    cloneOrder,
    doEvent,
    isLoadingOrders,
    isUpdating,
    subscribeToOrderUpdates,
    orderSubscription,
  } = useOrders();
  const theme = useTheme();
  const { orderStatusFilter, setOrderStatusFilter } = useDispatchOrders();

  const { isLoading: isPermsLoading } = usePermissions();
  const { setSearchQueryValue, searchValue } = useDataGridSearch();
  const { toasterStore, userStore, ordersStore } = useStores();
  const isLoading = isLoadingOrders || isPermsLoading;
  const companyId = userStore.currentCompanies[0].id || userStore.userCompany?.id;
  // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
  const loadingRef = useRef<boolean>(isLoading);

  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  const { createNewSite } = useSites();

  const userPermissions = useMemo(() => {
    return userStore.getPermissions();
  }, [userStore.getPermissions()]);

  let orderFilters = ordersStore.orderFilter;
  switch (orderStatusFilter) {
    case OrderStatusFilter.EXTERNAL:
      orderFilters = { ...orderFilters, states: externalOrderStates };
      break;
    case OrderStatusFilter.ACTIVE:
      orderFilters = { ...orderFilters, states: activeOrderStates };
      break;
    case OrderStatusFilter.PENDING:
      orderFilters = { ...orderFilters, states: pendingOrderStates };
      break;
    case OrderStatusFilter.INPROGRESS:
      orderFilters = { ...orderFilters, states: inProgressOrderStates };
      break;
    case OrderStatusFilter.DONE:
      orderFilters = { ...orderFilters, states: doneOrderStates };
      break;
    default:
      break;
  }

  const onFormStateChange = ({ isDirty }: FormStateChangeProps) => {
    setCurrentFormDirty(isDirty);
  };
  const rows = userStore.isCurrentCompanyActive
    ? ordersStore.orders
    : ordersStore.childCompanyOrders;

  const findStoredOrder = useCallback((orderId: string, source: Order[]) => {
    const storedOrder = source.find(({ id }) => id === orderId);

    return storedOrder ?? null;
  }, []);

  const selectedOrder = useMemo(() => {
    if (!selectedOrderId) {
      return null;
    }

    let order: Order | null = findStoredOrder(
      selectedOrderId,
      userStore.isCurrentCompanyActive
        ? ordersStore.orders
        : ordersStore.childCompanyOrders,
    );

    if (!order) {
      order = findStoredOrder(selectedOrderId, ordersStore.clonedOrders);
    }

    return order;
  }, [selectedOrderId, rows, ordersStore.clonedOrders, findStoredOrder]);
  const createNew = () => {
    setSelectedOrderId(null);
    setIsOrderDialogOpen(true);
  };
  const handleClose = async () => {
    await orderActionsRef.current?.fileAttachmentsOnClose();
    setIsOrderDialogOpen(false);
  };
  const onSuccess = async (order: Order) => {
    await orderActionsRef.current?.fileAttachmentsOnSubmit(order.id);
    setSelectedOrderId(null);
    setIsOrderDialogOpen(false);
    toasterStore.push(
      alert(
        selectedOrderId
          ? $t('order.order_updated', { name: order?.orderId })
          : $t('order.order_created', { name: order?.orderId }),
        AlertTypes.success,
      ),
    );
  };

  const onChangeFilter = (searchQuery: string) => {
    setSearchQueryValue(searchQuery);
  };
  const onSubmitOrderForm = () => {
    orderActionsRef.current?.submit(onSubmitOrderFormCallBack);
  };
  const onSubmitOrderFormCallBack = async (data: NewOrderFormSchemaInterface) => {
    if (selectedOrderId) {
      const newData = await createSitesInline(
        data,
        createNewSite,
        userStore.userCompany?.siteRadius,
      );
      await updateOrder({
        order: newData as NewOrderFormSchemaInterface,
        callBack: onSuccess,
      });
    } else {
      const newData = await createSitesInline(
        data,
        createNewSite,
        userStore.userCompany?.siteRadius,
      );
      await createOrder({
        order: newData as NewOrderFormSchemaInterface,
        callBack: onSuccess,
      });
    }
  };
  const editRow = (id: string) => {
    setSelectedOrderId(id);
    setIsOrderDialogOpen(true);
  };

  const cancelOrder = (id: string) => {
    setSelectedOrderId(id);
    modalDialogRef.current?.open();
  };

  const copyRow = (id: string) => {
    setSelectedOrderId(id);
    cloneModalDialogRef.current?.open();
  };

  const handleCopyWithAssignees = async (id: string, includeAssignees: boolean) => {
    let clonedOrderId = '';

    try {
      const order = await cloneOrder(id, includeAssignees);
      clonedOrderId = order.id;

      toasterStore.push(
        alert($t('dispatch.order.copied', { name: order?.orderId }), AlertTypes.success),
      );
    } finally {
      cloneModalDialogRef.current?.close();

      if (clonedOrderId) {
        editRow(clonedOrderId);
      }
    }
  };

  const doAction = (id: string, action: OrderEventType) => {
    doEvent(id, action).then((response) => {
      setSelectedOrderId(null);
      modalDialogRef.current?.close();
      const toasterMessage =
        action === 'cancel' ? 'dispatch.order.canceled' : 'dispatch.order.updated';
      toasterStore.push(
        alert($t(toasterMessage, { name: response.orderId }), AlertTypes.success),
      );
    });
  };

  const getFilteredCompanyOrders = (link?: PaginationLink, searchValue?: string) => {
    if (userStore.isCurrentCompanyActive) {
      getCompanyOrders(link || ({} as PaginationLink), searchValue, orderFilters);
    } else {
      getOrdersByCompanyId({
        companyId,
        link: link || ({} as PaginationLink),
        searchQuery: searchValue,
        filterParams: orderFilters,
      });
    }
  };

  useEffect(() => {
    getFilteredCompanyOrders({} as PaginationLink, searchValue);
  }, [orderStatusFilter, searchValue, companyId]);

  useEffect(() => {
    // Unsubscribe from the existing subscription and start a new one when the company id changes
    // To allow for RTUs when viewing orders for children companies
    if (orderSubscription) {
      orderSubscription.unsubscribe();
    }

    if (companyId) {
      subscribeToOrderUpdates();
    }

    return () => {
      orderSubscription?.unsubscribe();
    };
  }, [companyId]);

  const accountsColumns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'company',
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row) => row.company?.legalName))],
          headerName: $t('form_fields.company'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.value?.legalName;
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <>
                <BasicTooltip title={params.row?.company?.legalName}>
                  <Typography component="span">
                    {params.row.company?.legalName}
                  </Typography>
                </BasicTooltip>
              </>
            );
          },
        },
        {
          field: 'projectName',
          headerName: $t('order.project_name'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row: Order) => row?.projectName))],
          groupable: true,
          aggregable: false,
          flex: 1,
        },
        {
          field: 'orderId',
          headerName: $t('order.form.order_id'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row: Order) => row?.orderId))],
          groupable: true,
          aggregable: false,
          flex: 1,
        },
        {
          field: 'dispatchNumber',
          headerName: $t('order.form.dispatch_number'),
          type: 'singleSelect',
          valueOptions: [
            ...new Set(rows.map((row: Order) => row?.dispatchNumber).filter(Boolean)),
          ],
          groupable: true,
          aggregable: false,
          width: 90,
          renderCell: (params: GridValueGetterParams) => {
            return (
              <>
                <BasicTooltip title={params.row?.dispatchNumber}>
                  <Typography component="span" variant="body1">
                    {params.row?.dispatchNumber}
                  </Typography>
                </BasicTooltip>
              </>
            );
          },
        },
        {
          field: 'account',
          headerName: $t('form_fields.account'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row: Order) => row.account?.name))],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.row.account?.name || '';
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <>
                <BasicTooltip title={params?.value}>
                  <Typography component="span">{params?.value}</Typography>
                </BasicTooltip>
              </>
            );
          },
        },
        {
          field: 'department',
          type: 'singleSelect',
          valueOptions: [
            ...new Set(
              rows.filter((row) => !!row?.department).map((row) => row?.department?.name),
            ),
          ],
          headerName: $t('form_fields.department'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.value?.name;
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <>
                <BasicTooltip title={params.row?.department?.name}>
                  <Typography component="span">{params.row.department?.name}</Typography>
                </BasicTooltip>
              </>
            );
          },
        },
        {
          field: 'service',
          headerName: $t('order.form.service'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row: Order) => row.service?.name))],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.row.service?.name || '';
          },
        },
        {
          field: 'material',
          headerName: $t('form_fields.materials'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row: Order) => row.material?.name))],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.row.material?.name || '';
          },
        },
        {
          field: 'equipmentType',
          headerName: $t('form_fields.equipment_type'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row: Order) => row.equipmentType?.name))],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.row.equipmentType?.name || '';
          },
        },
        {
          field: 'truckCount',
          type: 'number',
          headerName: $t('form_fields.truck_count'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.row.truckCount;
          },
        },
        {
          field: 'truckDelay',
          type: 'number',
          headerName: $t('form_fields.truck_delay'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.row.truckDelay;
          },
        },
        {
          field: 'state',
          headerName: $t('form_fields.status'),
          type: 'singleSelect',
          minWidth: 80,
          valueOptions: [...new Set(rows.map((row: Order) => row.state))],
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            if (params.row.state === OrderState.PENDING_REQUEST) {
              return (
                <>
                  <DataGridSmallButton
                    variant="contained"
                    color="success"
                    onClick={() => doAction(params.row.id, 'accept')}
                  >
                    {t('actions.accept')}
                  </DataGridSmallButton>

                  <DataGridSmallButton
                    variant="contained"
                    color="error"
                    onClick={() => doAction(params.row.id, 'reject')}
                  >
                    {t('actions.decline')}
                  </DataGridSmallButton>
                </>
              );
            }

            return <OrderStatus status={params.value} />;
          },
        },
        {
          field: 'quantity',
          headerName: $t('form_fields.quantity'),
          type: 'number',
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.row.quantity;
          },
        },
        {
          field: 'unitOfMeasure',
          headerName: $t('form_fields.unit'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row: Order) => row.unitOfMeasure?.name))],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.row.unitOfMeasure?.name;
          },
        },
        {
          field: 'loadAt',
          headerName: $t('form_fields.start_date_and_time'),
          flex: 1,
          type: 'dateTime',
          valueGetter: (params: GridValueGetterParams) => {
            return params.row.loadAt ? new Date(params.row.loadAt) : null;
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                title={`${$t('form_fields.load_at')}: ${dateFormat(params?.row?.loadAt)}`}
              >
                <Typography component="span">
                  {params.row.loadAt ? dateFormat(params.row.loadAt) : ''}
                </Typography>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'sendingAccounts',
          headerName: $t('order.form.for'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return (
              <Typography fontSize="12px">
                {params.row.sendingAccounts.length
                  ? params.row.sendingAccounts
                      .map((account: Account_Read_Nested) => account.name)
                      .join(', ')
                  : params.row.account?.name}
              </Typography>
            );
          },
        },
        {
          field: 'loadCycleAvg',
          headerName: `${$t('form_fields.load_cycle_avg')}`,
          type: 'number',
          flex: 1,
          minWidth: 40,
          renderCell: (params: GridValueGetterParams) => {
            return params.value ? (
              <>
                {params.value && (
                  <Typography variant="body1">
                    {minutesToHoursMins(params.value)}
                  </Typography>
                )}
              </>
            ) : null;
          },
        },
        {
          field: 'notes',
          headerName: $t('form_fields.notes'),
          flex: 1,
        },
        {
          field: 'pickUpSite',
          headerName: $t('form_fields.pick_up_site'),
          // Type: 'singleSelect',
          // ValueOptions: [
          //   ...new Set(
          //     Rows.map((row: Order) => get(row, 'pickUpWayPoints.[0].site.name', '')),
          //   ),
          // ],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return get(params.row, 'pickUpWayPoints[0].site.name', '');
          },
        },
        {
          field: 'dropOffSite',
          headerName: $t('form_fields.drop_off_site'),
          // Type: 'singleSelect',
          // ValueOptions: [
          //   ...new Set(
          //     Rows.map((row: Order) => get(row, 'dropOffWayPoints.[0].site.name', '')),
          //   ),
          // ],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return get(params.row, 'dropOffWayPoints[0].site.name', '');
          },
        },
        {
          field: 'actions',
          headerName: $t('actions.actions'),
          type: 'actions',
          flex: 1,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          hideable: false,
          renderHeader: () => (
            <HeaderNavigation
              count={
                userStore.isCurrentCompanyActive
                  ? ordersStore.orders?.length
                  : ordersStore.childCompanyOrders?.length || 0
              }
              loading={loadingRef.current}
              pagination={
                userStore.isCurrentCompanyActive
                  ? ordersStore.ordersPagination
                  : ordersStore.childCompanyOrdersPagination
              }
              callback={getFilteredCompanyOrders}
              altText={`${$t('actions.actions')}`}
              searchQuery={searchValue}
            />
          ),
          renderCell: (params) => {
            const { editable, state } = params.row;
            const actions = [];

            if (userPermissions.canEditOrder && editable) {
              actions.push({
                title: `${t('dispatch.order.edit_order')}`,
                icon: <Edit />,
                callBack: () => editRow(params.row.id),
              });
            }

            const isCloneable = editable && userPermissions.canCreateOrder;
            if (isCloneable) {
              actions.push({
                title: `${t('dispatch.order.clone_order')}`,
                icon: <FileCopyOutlined />,
                callBack: () => copyRow(params.row.id),
              });
            }

            if (
              userPermissions.canDeleteOrder &&
              editable &&
              ![OrderState.REJECTED, OrderState.CANCELED, OrderState.COMPLETED].includes(
                state,
              )
            ) {
              actions.push({
                title: `${t('dispatch.order.cancel_order')}`,
                icon: <CancelOutlined />,
                callBack: () => cancelOrder(params.row.id),
              });
            }

            return params.row.id ? (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  width: '100%',
                  justifyContent: 'end',
                }}
              >
                <SimpleMenu options={actions}>
                  <MoreHoriz />
                </SimpleMenu>
              </Box>
            ) : null;
          },
        },
      ] as GridColDef[],
    [rows, userPermissions],
  );

  const initialState = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          projectId: false,
          unitOfMeasure: false,
          notes: false,
          department: false,
          loadCycleAvg: false,
        },
      },
    }),
    [],
  );

  const statusFilters = [
    { value: OrderStatusFilter.EXTERNAL, label: 'dispatch.tabs.external' },
    { value: OrderStatusFilter.ACTIVE, label: 'dispatch.tabs.active' },
    { value: OrderStatusFilter.PENDING, label: 'dispatch.tabs.pending' },
    { value: OrderStatusFilter.INPROGRESS, label: 'dispatch.tabs.in_progress' },
    { value: OrderStatusFilter.DONE, label: 'dispatch.tabs.done' },
    { value: OrderStatusFilter.All, label: 'dispatch.tabs.all' },
  ];

  return (
    <>
      <Box>
        <Box mb={2}>
          <StatusTabFilters
            value={orderStatusFilter as OrderStatusFilter}
            onChange={(tab) => setOrderStatusFilter(tab)}
            filters={statusFilters}
          />
        </Box>

        <DataGrid
          id="orders-data-grid"
          columns={accountsColumns}
          rows={rows as unknown as Record<string, any>[]}
          loading={isLoading}
          initialState={initialState}
          onChangeFilter={onChangeFilter}
          headerActionsComponent={
            <>
              {userPermissions.canCreateOrder && (
                <Box display={'flex'}>
                  <Button
                    data-test-id={'create-company-btn'}
                    color={'primary'}
                    onClick={createNew}
                    startIcon={<Add />}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {t('order.create_order')}
                  </Button>
                </Box>
              )}
            </>
          }
        />
      </Box>
      <Dialog
        open={isOrderDialogOpen}
        onClose={(_: never, reason: DialogCloseReasonType) => {
          isActionClicked(reason) && handleClose();
        }}
        maxWidth={'lg'}
      >
        <DialogHeader
          closeCallBack={handleClose}
          title={
            <>
              <Typography component={'span'} variant={'h5'}>
                {selectedOrderId ? t('order.update_order') : t('order.create_order')}
              </Typography>
              <Typography>{selectedOrder?.orderId}</Typography>
            </>
          }
        />
        <DialogContent
          sx={{
            backgroundColor: theme.palette.grey[100],
          }}
        >
          <NewOrderForm
            defaultOrder={selectedOrder}
            ref={orderActionsRef}
            onFormStateChange={onFormStateChange}
          />
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row-reverse',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <LoadingButton
            disabled={isUpdating || !currentFormDirty}
            loading={isUpdating}
            loadingPosition="start"
            startIcon={<></>}
            onClick={onSubmitOrderForm}
            data-test-id={'create-order-btn'}
            type="button"
            variant="contained"
            color="primary"
            sx={isUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
          >
            {$t(`actions.${selectedOrderId ? 'submit' : 'create'}`)}
          </LoadingButton>
          <Button
            onClick={handleClose}
            sx={{ mr: 2, px: 2 }}
            disabled={isUpdating}
            color="secondary"
            variant="outlined"
          >
            {$t('actions.cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <ModalDialog
        ref={modalDialogRef}
        loading={isUpdating}
        title={$t('dispatch.order.cancel_order')}
        content={$t('dispatch.order.cancel_description')}
        confirmButtonColor={'error'}
        callBack={() => doAction(selectedOrderId || '', 'cancel')}
      />

      <ModalDialog
        ref={cloneModalDialogRef}
        loading={isUpdating}
        title={$t('dispatch.order.clone_order')}
        content={$t('dispatch.order.clone_description')}
        confirmButtonColor={'primary'}
        cancelButtonText={`${$t('dispatch.order.copy_without_assignments')}`}
        confirmButtonText={`${$t('dispatch.order.copy_with_assignments')}`}
        callBack={() => handleCopyWithAssignees(selectedOrderId || '', true)}
        onCancel={() => handleCopyWithAssignees(selectedOrderId || '', false)}
      />
    </>
  );
});
export { OrdersDataGrid };
