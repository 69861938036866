import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { SxProps } from '@mui/system';
import { OrderState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { ReactNode } from 'react';
import React from 'react';

import { Column, FlexColumn } from '~components/Order/ordersDispatchStyledComponents';
import { Order } from '~hooks/useOrders';
import { WayPoint } from '~hooks/useSites';
import {
  Alerts,
  BulkAssignMultipleCTA,
  ChildCompanyBadge,
  CycleTime,
  DeliveredSummary,
  DropffWaypoint,
  EditOrderCTA,
  EquipmentType,
  InternalNotes,
  JobNotes,
  OrderNotes,
  PickupWaypoint,
  Requester,
  ServiceType,
  StartTime,
  Status,
  Subtitle,
  ThreeDotsMenu,
  Title,
  TrucksSummary,
} from '~pages/Dispatch/components/OrderDispatchCardFragments';
import { rootStore } from '~store';
import theme from '~theme/AppTheme';

export enum OrdersDispatchColumnKey {
  CHECKBOX = 'checkbox',
  DISPATCH = 'dispatch',
  COMPANY = 'company',
  TRUCKS_AND_TYPES = 'trucksandtypes',
  FOR = 'for',
  START = 'start',
  STATUS = 'status',
  WAYPOINTS = 'waypoints',
  MATERIAL = 'material',
  CYCLE = 'cycle',
  ALERTS = 'alerts',
  ACTIONS = 'actions',
}

export const orderDispatchColumns: Record<
  OrdersDispatchColumnKey,
  { title: ReactNode; size: string; sx?: SxProps; condition?: () => boolean }
> = {
  [OrdersDispatchColumnKey.CHECKBOX]: {
    title: '',
    size: '20px',
    sx: { px: 0 },
  },
  [OrdersDispatchColumnKey.DISPATCH]: {
    title: t('dispatch.dispatch_v2.dispatch_ext_id'),
    size: '1fr',
  },
  [OrdersDispatchColumnKey.COMPANY]: {
    title: t('dispatch.dispatch_v2.company'),
    size: '2fr',
    condition: () => rootStore?.userStore?.userCompanies?.length > 1 || false,
  },
  [OrdersDispatchColumnKey.FOR]: {
    title: t('dispatch.dispatch_v2.for_and_customer'),
    size: '2fr',
  },
  [OrdersDispatchColumnKey.TRUCKS_AND_TYPES]: {
    title: t('dispatch.dispatch_v2.trucks_and_type'),
    size: '2fr',
  },
  [OrdersDispatchColumnKey.START]: {
    title: t('dispatch.dispatch_v2.start'),
    size: '1fr',
  },
  [OrdersDispatchColumnKey.CYCLE]: {
    title: t('dispatch.dispatch_v2.cycle_time'),
    size: '80px',
  },
  [OrdersDispatchColumnKey.STATUS]: {
    title: t('form_fields.status'),
    size: '105px',
    sx: { display: 'flex', gap: theme.spacing(0.5), px: 0 },
  },
  [OrdersDispatchColumnKey.WAYPOINTS]: {
    title: t('dispatch.dispatch_v2.pick_up_and_drop_off'),
    size: '2fr',
  },
  [OrdersDispatchColumnKey.MATERIAL]: {
    title: t('dispatch.dispatch_v2.material_delivered'),
    size: '1fr',
  },
  [OrdersDispatchColumnKey.ALERTS]: {
    title: t('actions.alerts'),
    size: '1fr',
  },
  [OrdersDispatchColumnKey.ACTIONS]: {
    title: '',
    size: '120px',
    sx: { display: 'flex', justifyContent: 'flex-end', px: 0 },
  },
} as const;

export const makeOrderDispatchCardTemplateColumns = (
  isSidebarCollapsed: boolean = false,
) => {
  if (isSidebarCollapsed) {
    return ['0px', '1fr', 'auto'].join(' ');
  }

  return [
    orderDispatchColumns[OrdersDispatchColumnKey.CHECKBOX].size,
    '1fr',
    'auto',
  ].join(' ');
};

interface OrderDispatchColumnProps {
  order: Order;
}

interface CheckboxColumnProps {
  isChecked?: boolean;
  isDisabled?: boolean;
  onCheckedStateChange?: () => void;
}

export function CheckboxColumn({
  isChecked,
  isDisabled,
  onCheckedStateChange,
}: CheckboxColumnProps) {
  return (
    <Column columnKey={OrdersDispatchColumnKey.CHECKBOX}>
      <Checkbox
        size="small"
        sx={{
          '&.MuiCheckbox-root': {
            color: theme.brandV2.colors.treadGray6,
            mt: '-10px',
            p: 0,
          },
          '&.Mui-checked': { color: theme.brandV2.colors.treadOrange },
          '&.Mui-disabled': { pointerEvents: 'auto' },
        }}
        disabled={Boolean(isDisabled)}
        checked={Boolean(isChecked)}
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
          onCheckedStateChange?.();
        }}
      />
    </Column>
  );
}

export const extractWaypointData = (waypoint?: WayPoint) => {
  if (!waypoint?.siteNested) {
    return null;
  }

  const name = waypoint.siteNested.name;
  const address = waypoint.siteNested.address?.thoroughfare;
  const geofenceType = waypoint.siteNested.nextBillionGeofence?.geofenceType;

  return { name, address, geofenceType };
};

export function MainColumn({ order }: OrderDispatchColumnProps) {
  return (
    <Column columnKey={OrdersDispatchColumnKey.DISPATCH}>
      <FlexColumn>
        <Title order={order} />
        <Subtitle order={order} />
      </FlexColumn>
    </Column>
  );
}

interface ActionsColumnProps extends OrderDispatchColumnProps {
  nonRoutableAlertsFeatureFlagEnabled?: boolean;
  onAccept: () => void;
  onBulkAssignMultiple?: () => void;
  onCancel: () => void;
  onCloneOrder: () => void;
  onEdit: () => void;
  onOrderStateChange: (nextOrderState: OrderState) => Promise<void>;
  onReject: () => void;
  onTextAllDrivers: () => void;
}

export function ActionsColumn({
  nonRoutableAlertsFeatureFlagEnabled,
  onAccept,
  onBulkAssignMultiple,
  onCancel,
  onCloneOrder,
  onEdit,
  onOrderStateChange,
  onReject,
  onTextAllDrivers,
  order,
}: ActionsColumnProps) {
  return (
    <Column
      columnKey={OrdersDispatchColumnKey.ACTIONS}
      sx={{ display: 'flex', alignItems: 'flex-start', pr: 0 }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <ChildCompanyBadge order={order} />
        <Status
          onAccept={onAccept}
          onOrderStateChange={onOrderStateChange}
          onReject={onReject}
          order={order}
        />

        <Alerts
          nonRoutableAlertsFeatureFlagEnabled={nonRoutableAlertsFeatureFlagEnabled}
          order={order}
        />

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <BulkAssignMultipleCTA order={order} onClick={onBulkAssignMultiple} />

          <EditOrderCTA order={order} onClick={onEdit} />

          <ThreeDotsMenu
            onCancelClick={onCancel}
            onCloneOrderClick={onCloneOrder}
            onTextAllDriversClick={onTextAllDrivers}
            order={order}
          />
        </Box>
      </Box>
    </Column>
  );
}

export function BottomColumn({ order }: OrderDispatchColumnProps) {
  return (
    <Column
      columnKey={OrdersDispatchColumnKey.DISPATCH}
      sx={{
        alignItems: 'flex-start',
        display: 'flex',
        gap: 3,
        gridColumn: 'span 3',
        justifyContent: 'space-between',
        mx: -1,
        pl: `calc(${orderDispatchColumns[OrdersDispatchColumnKey.CHECKBOX].size} + ${theme.spacing(2)})`,
        pr: 0,
        width: '100%',
      }}
    >
      <Box sx={{ alignItems: 'flex-start', display: 'flex', gap: 3, width: '100%' }}>
        <FlexColumn sx={{ flex: '0 0 auto', overflow: 'hidden' }}>
          <StartTime order={order} />
          <Requester order={order} />
        </FlexColumn>

        <FlexColumn sx={{ flex: '0 0 auto', overflow: 'hidden' }}>
          <ServiceType order={order} />
          <EquipmentType order={order} />
        </FlexColumn>

        <FlexColumn sx={{ flex: '0 0 auto', overflow: 'hidden' }}>
          <PickupWaypoint order={order} />
          <DropffWaypoint order={order} />
        </FlexColumn>

        <FlexColumn sx={{ flex: 1, overflow: 'hidden' }}>
          <OrderNotes order={order} />
          <InternalNotes order={order} />
          <JobNotes order={order} />
        </FlexColumn>

        <Box
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flex: '0 0 auto',
            gap: 3,
            overflow: 'hidden',
          }}
        >
          <CycleTime order={order} />
          <TrucksSummary order={order} />
          <DeliveredSummary order={order} />
        </Box>
      </Box>
    </Column>
  );
}
