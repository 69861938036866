import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { t } from 'i18next';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { unitOfMeasureOptions } from '~constants/enums';
import { Material, useMaterials } from '~hooks/useMaterials';
import { Order } from '~hooks/useOrders';
import { Project } from '~hooks/useProjects';
import { useServiceClass } from '~hooks/useServiceClass';
import { Service } from '~hooks/useServices';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

import { serviceIsHiredTruck, serviceIsMaterialDelivery } from '../newOrderFormSchema';

interface OrderDetailsV2Props {
  order?: Order;
  parent?: Project | Order;
}

export default function OrderDetailsV2({ order, parent }: OrderDetailsV2Props) {
  const form = useFormContext();

  const { companyAssetsStore, userStore, toasterStore } = useStores();

  const { getServiceClassesByCompanyId } = useServiceClass();
  const {
    createMaterialByCompanyId,
    getMaterialsByCompanyId,
    getMaterialsByCompanyIdTypeahead,
    isLoading: isMaterialLoading,
  } = useMaterials();

  const [notFoundMaterialName, setNotFoundMaterialName] = useState('');

  const watchedCompany = form.watch('company');
  const watchedService = form.watch('service') as Service | null;

  const companyId = watchedCompany?.id || userStore.userCompany?.id || '';
  const isHiredTruckService = serviceIsHiredTruck(watchedService);
  const isMaterialDeliveryService = serviceIsMaterialDelivery(watchedService);

  const createMaterial = useCallback(async () => {
    if (!companyId) {
      toasterStore.push(
        alert(t('form_validation_errors.no_user_company'), AlertTypes.error),
      );

      return;
    }

    const createdMaterial = await createMaterialByCompanyId({
      companyId,
      material: { name: notFoundMaterialName } as unknown as Material,
    });

    form.setValue('material', createdMaterial, {
      shouldValidate: true,
      shouldDirty: true,
    });

    toasterStore.push(
      alert(
        t('administration.material.material_created', { name: createdMaterial.name }),
        AlertTypes.success,
      ),
    );
  }, [companyId, notFoundMaterialName]);

  const handleMaterialInput = useCallback((_: React.SyntheticEvent, value: string) => {
    setNotFoundMaterialName(value);
  }, []);

  useEffect(() => {
    if (!order?.id && parent) {
      const firstMaterial = (parent as Project).projectMaterialTotals?.[0];

      form.setValue('service', parent.service);
      form.setValue('serviceClass', parent.serviceClass);
      form.setValue('loadsPerTruck', parent.loadsPerTruck);
      form.setValue('unitsPerHour', parent.unitsPerHour);
      form.setValue('material', firstMaterial?.material);
      form.setValue('unitOfMeasure', firstMaterial?.unitOfMeasure);
    }
  }, [order?.id, parent]);

  useEffect(() => {
    if (companyId && !order?.id) {
      getServiceClassesByCompanyId(companyId);
      getMaterialsByCompanyId(companyId);
      form.setValue('material', null);
    }
  }, [companyId, order?.id]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AutocompleteFormField
            control={form.control}
            errors={form.formState.errors}
            getLabel={(item) => item?.name ?? ''}
            getValue={(item) => item?.id ?? ''}
            isRequired
            label={`${t('form_fields.service_type')}`}
            list={companyAssetsStore.services}
            name="service"
          />
        </Grid>

        <Grid item xs={6}>
          <AutocompleteFormField
            clearable
            control={form.control}
            errors={form.formState.errors}
            getLabel={(item) => item?.name ?? ''}
            getValue={(item) => item?.id ?? ''}
            label={`${t('form_fields.service_class')}`}
            list={companyAssetsStore.serviceClasses}
            name="serviceClass"
          />
        </Grid>

        <Grid item xs={6}>
          <AutocompleteAsyncFormField
            asyncCallback={getMaterialsByCompanyIdTypeahead}
            control={form.control}
            errors={form.formState.errors}
            extraRequestOptions={{ companyId }}
            getLabel={(material: Material) => {
              return `${material?.externalId ? `${material.externalId} - ` : ''}${material?.name ?? ''}`;
            }}
            getValue={(material: Material) => material?.id ?? ''}
            isRequired={!isHiredTruckService}
            label={`${t('form_fields.material')}`}
            name="material"
            clearable={isHiredTruckService}
            noOptionsText={
              notFoundMaterialName ? (
                <Chip
                  color="primary"
                  disabled={isMaterialLoading}
                  label={t('common.add_new_name', { name: notFoundMaterialName || '' })}
                  onClick={createMaterial}
                  size="small"
                  variant="filled"
                  sx={{ cursor: 'pointer' }}
                />
              ) : (
                t('form_fields.no_options')
              )
            }
            onInput={handleMaterialInput}
          />
        </Grid>

        <Grid item xs={3}>
          <TextFormField
            control={form.control}
            errors={form.formState.errors}
            isRequired={isMaterialDeliveryService}
            label={`${t('form_fields.ordered_quantity')}`}
            name="quantity"
            type="number"
          />
        </Grid>

        <Grid item xs={3}>
          <AutocompleteFormField
            control={form.control}
            errors={form.formState.errors}
            getLabel={(item) => item?.name ?? ''}
            getValue={(item) => item?.id ?? ''}
            isRequired={!isHiredTruckService}
            label={`${t('form_fields.units')}`}
            list={unitOfMeasureOptions}
            name="unitOfMeasure"
            clearable={isHiredTruckService}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
